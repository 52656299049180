import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const KPI = `${REACT_APP_API_URL}/kpi/`

export function getIssueRiskKPI(filter) {
  return axios.post<{result: any}>(KPI + 'issue-risk', filter);
}

export function getIssueResolvedKPI(filter) {
  return axios.post<{result: any}>(KPI + 'issue-status', filter);
}

export function getIssueLocation(filter) {
  return axios.post<{result: any}>(KPI + 'issue-category', filter);
}

export function getMaintenanceScheduledKPI(filter) {
  return axios.post<{result: any}>(KPI + 'scheduled-maintenance', filter);
}

export function getMaintenanceComplianceKPI(filter) {
  return axios.post<{result: any}>(KPI + 'scheduled-maintenance-compliance', filter);
}

export function getMTTRKPI(filter) {
  return axios.post<{result: any}>(KPI + 'middle-time', filter);
}

export function getRevisionsTime(filter) {
  return axios.post<{result: any}>(KPI + 'revisions-time', filter);
}

export function getBacklogKPI(filter) {
  return axios.post<{result: any}>(KPI + 'backlog', filter);
}

