import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2';
import { getRevisionsTime } from '../../redux/DashboardCRUD';

const ChartRevisions: React.FC<any> = ({ filter, modal }) => {
  const { t } = useTranslation();
  const [dataKPI, setDataKPI] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (filter) {
      getRevisionsTime(filter)
        .then((res: any) => {
          const valores = res.data.data.map(d => d);
          const noCero = valores.filter(v => v !== 0);

          if (noCero.length) {
            setLabels(res.data.labels.map(l => t(l)))
            setDataKPI(res.data.data);
          }
          else {
            setDataKPI(null);
            setLabels([]);
          }
        })
    }
  }, [filter, t])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5' style={{ minHeight: '40px' }}>
        <label className='fw-bolder mx-auto' style={{ fontSize: modal ? '20px' : '12px', marginBottom: modal ? '1.25rem' : '' }}>{t('reviewsAmount')}</label>
      </div>

      <div className='card-body mb-0 pt-0' style={{ height: modal ? '450px' : '200px' }}>
        {
          dataKPI ?
            <Line
              data={{
                labels: labels,
                datasets: [
                  {
                    data: dataKPI,
                    borderWidth: 3,
                    fill: false,
                    showLine: true,
                    borderColor: 'rgb(10,73,123)',
                    tension: 0.1
                  }
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    grid: {
                      display: false
                    }
                  },
                  y: {
                    grid: {
                      display: false
                    }
                  }
                }
              }}
            />
            : <div style={{ marginTop: '25%' }}>
              <h5 className='text-center'>{t('noData')}</h5>
            </div>
        }
      </div>
    </div>
  )
}

export { ChartRevisions };