var moment = require('moment');

export const date = moment();
export const last_month = moment().add(-1, 'month');
export const last_month_number = moment().add(-1, 'month').month() + 1;
export const current_month = moment().month() + 1;
export const current_year = moment().year();
export const last_year = moment().add(-1, 'year');
export const today = moment().format('DD/MM/YYYY');
export const last_year_from = last_year.format('DD/MM/YYYY');
export const first_day_year = moment('1/1/' + moment().year(),'DD/MM/YYYY').format('DD/MM/YYYY');
export const first_day_last_year = moment('1/1/' + last_year.year(), 'DD/MM/YYYY').format('DD/MM/YYYY');
export const last_day_last_year = moment('31/12/' + last_year.year(), 'DD/MM/YYYY').format('DD/MM/YYYY');
export const first_day_month = moment('1/'+ current_month +'/' + current_year,'DD/MM/YYYY').format('DD/MM/YYYY');
export const first_day_last_month = moment('1/'+ last_month_number +'/' + last_month.year(),'DD/MM/YYYY').format('DD/MM/YYYY');
const last_day_last_month_number = last_month.daysInMonth();
export const last_day_last_month = moment(last_day_last_month_number + '/'+ last_month_number +'/' + last_month.year(),'DD/MM/YYYY').format('DD/MM/YYYY');

export const ultimoAnio = () => {
    return `(${last_year_from} - ${today})`
}

export const anioActual = () => {
    return `(${first_day_year} - ${today})`
}

export const anioAnterior = () => {
    return `(${first_day_last_year} - ${last_day_last_year})`
}

export const mesActual = () => {
    return `(${first_day_month} - ${today})`
}

export const mesAnterior = () => {
    return `(${first_day_last_month} - ${last_day_last_month})`
}

export const getPeriodsList = (t) => {
    return [
        { id: "ONE_YEAR", descripcion: `${t('lastYear')} ${ultimoAnio()}`},
        { id: "ACTUAL_YEAR", descripcion: `${t('currentYear')} ${anioActual()}` },
        { id: "LAST_YEAR", descripcion: `${t('previousYear')} ${anioAnterior()}` },
        { id: "ACTUAL_MONTH", descripcion: `${t('currentMonth')} ${mesActual()}` },
        { id: "LAST_MONTH", descripcion: `${t('previousMonth')} ${mesAnterior()}` }
    ];
}
