import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIssueLocation } from '../../redux/DashboardCRUD';
import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts';

const defaultOptions: ApexOptions = {
  series: [{
    data: [
      { x: '', y: 1 }
    ]
  }],
  chart: {
    type: 'treemap',
    height: '100%',
    redrawOnParentResize: true,
    toolbar: {
      show: false
    },

  },
  colors: [
    '#9bc2e6',
  ],
  legend: {
    show: false
  },
  plotOptions: {
    treemap: {
      distributed: true,
      enableShades: false,
    }
  },
};
const ChartIssueLocation: React.FC<any> = ({ filter, modal }) => {
  const { t } = useTranslation();
  const [dataKPI, setDataKPI] = useState([]);
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    if (filter) {
      getIssueLocation(filter)
        .then((res: any) => {
          const valores = res.data.map(d => d.count);
          const noCero = valores.filter(v => v !== '0');

          if (noCero.length) {
            const dataRes = res.data.map(d => {
              return { "x": d.category_name, "y": Number.parseInt(d.count) }
            });
            const colores = res.data.map(d => d.color);

            const optionsChart: ApexOptions = {
              ...options,
              colors: colores
            }
            setOptions(optionsChart);
            setDataKPI([{
              data: dataRes
            }])
          }
          else {
            setDataKPI(null)
          }
        })
    }
  }, [filter])


  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5' style={{ minHeight: '40px' }}>
        <label className='fw-bolder mx-auto' style={{ fontSize: modal ? '20px' : '12px', marginBottom: modal ? '1.25rem' : '' }}>{t('incidentLocation')}</label>
      </div>

      <div className='card-body p-0' style={{ height: modal ? '450px' : '200px' }}>
        {
          dataKPI ?
            <div style={{ paddingLeft: '3%' }}>
              <ReactApexChart
                type='treemap'
                options={options}
                height={modal ? 430 : 190}
                series={dataKPI}>
              </ReactApexChart>
            </div>
            :
            <div style={{ marginTop: '25%' }}>
              <h5 className='text-center'>{t('noData')}</h5>
            </div>
        }
      </div>
    </div>
  )
}

export { ChartIssueLocation };