import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import StringFilter from "@inovua/reactdatagrid-community/StringFilter";
import { i18nTable, sanitize } from "../../../../../core/Funciones";
import { getGroups, getReceivers, removeGroup, saveGroup } from "../../redux/ReportsCRUD";
import { Button, Modal } from "react-bootstrap-v5";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { MASCARAS } from "../../../../../core/FuncionesCampos";
import { mostrarError, mostrarExito } from "../../../../../core/EmisorMensajes";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const initialValuesGroup = {
    id: 0, name: '', receivers: []
}
const Group: FC<any> = ({ updateData, handleUpdateData }) => {
    const [groupsList, setGroupsList] = useState<any[]>([]);
    const [groupSeleccionado, setGroupSeleccionado] = useState<any>(initialValuesGroup);
    const [popupEdicionGroup, setPopupEdicionGroup] = useState(false);
    const [popupEliminarGroup, setPopupEliminarGroup] = useState(false);
    const [refreshData, setRefreshData] = useState(true);
    const [receiversList, setReceiversList] = useState<any[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        setRefreshData(true);
        handleUpdateData(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData])

    useMemo(() => {
        if (refreshData) {
            getGroups().then((res: any) => {

                const list = [];
                res.data.forEach(g => {
                    list.push({
                        ...g,
                        receivers: g.receivers.map(r => ({
                            id: r.id,
                            value: r.id,
                            label: `${r.surname} ${r.name}`
                        }))
                    })
                })

                setGroupsList(list)
            });

            getReceivers().then((res: any) => {
                const list = [];
                res.data.forEach(r => list.push({
                    id: r.id,
                    value: r.id,
                    label: `${r.surname} ${r.name} (${r.email})`
                }));
                setReceiversList(list)
            });

            setRefreshData(false);
        }
        return () => setRefreshData(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshData]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().matches(MASCARAS.soloLetrasEspacioNumeros, t('soloLetrasEspacio')).required(t('campoRequerido')),
        receivers: Yup.array().min(1),
    });

    const togglePopupGroup = (data) => {
        setTimeout(() => {
            setPopupEdicionGroup(!popupEdicionGroup);
            if (!data) {
                setGroupSeleccionado(initialValuesGroup)
            }
            else setGroupSeleccionado(sanitize(data));
        });
    }

    const deleteGroup = () => {
        if (groupSeleccionado) {
            removeGroup(groupSeleccionado.id)
                .then((res: any) => {
                    if (res.status === 200) {
                        mostrarExito(t('changesApplied'));
                        setPopupEliminarGroup(false);
                        setGroupSeleccionado(initialValuesGroup);
                        setRefreshData(true);
                        handleUpdateData(true);
                    }
                    else {
                        mostrarError(t(res.data));
                    }
                })
                .catch(err => {
                    if (err?.response?.data) {
                        mostrarError(t(err.response.data));
                    }
                    else {
                        mostrarError(err);
                    }
                });
        }
    }

    const onChangeMultiselect = (value, event, values) => {
        let selection = [];
        if (event.action === "select-option" && event.option.value === "*") {
            selection = [{ label: t('all'), value: "*" }, ...receiversList];
        }
        else if (event.action === "deselect-option" && event.option.value === "*") {
            selection = [];
        }
        else if (value.length === receiversList.length) {
            selection = [{ label: t('all'), value: "*" }, ...receiversList];
        }
        else {
            selection = value;
        }

        setGroupSeleccionado({
            ...values,
            receivers: selection
        });
    }

    return (
        <div>
            <div className="d-flex justify-content-end">
                <Button className="btn btn-success btn-sm" onClick={() => { setPopupEdicionGroup(true); }}>
                    <i className="fa fa-plus" /> {t('addGroup')}
                </Button>
            </div>
            <ReactDataGrid
                idProperty="id"
                style={{ minHeight: 600, height: '75vh' }}
                rowHeight={25}
                columns={[
                    { name: 'name', header: t('name'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
                    {
                        name: 'receivers', header: t('recipientsList'), defaultFlex: 1, type: 'string', filterEditor: StringFilter,
                        render: ({ data }) => data && data.receivers ?
                            <span>{
                                data.receivers.map((r, index) =>
                                    `${r.label}${index !== (data.receivers.length - 1) ? ', ' : ''}`
                                )
                            }</span> : ''
                    },
                    {
                        name: 'acciones', header: t('actions'), defaultFlex: 1,
                        render: ({ data }) =>
                            <div className="p-0 text-center">
                                <button className="btn btn-sm" onClick={() => togglePopupGroup(data)}>
                                    <i className="fa fa-edit text-warning"></i>
                                </button>
                                <button className="btn btn-sm" onClick={() => {
                                    setTimeout(() => {
                                        setGroupSeleccionado(data);
                                        setPopupEliminarGroup(true);
                                    });
                                }} >
                                    <i className="fa fa-trash text-danger"></i>
                                </button>
                            </div>
                    },
                ]}
                dataSource={groupsList}
                i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                defaultFilterValue={[
                    { name: 'name', operator: 'contains', type: 'string', value: undefined },
                    { name: 'receivers', operator: 'contains', type: 'string', value: undefined },
                ]}
            />

            <React.Fragment>
                <Formik
                    initialValues={groupSeleccionado}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            let groupData = {
                                ...values,
                                id: groupSeleccionado ? groupSeleccionado.id : null
                            }

                            saveGroup(groupData)
                                .then(res => {
                                    if (res.status === 200) {
                                        mostrarExito(t("changesApplied"));
                                        togglePopupGroup(null);
                                        setRefreshData(true);
                                        handleUpdateData(true);
                                    }
                                })
                                .catch(err => {
                                    if (err?.response?.data) {
                                        mostrarError(err.response.data);
                                    }
                                    else {
                                        mostrarError(err);
                                    }
                                });
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, isSubmitting, handleSubmit, handleReset }) => (
                        <>
                            <Modal
                                show={popupEdicionGroup}
                                onHide={() => togglePopupGroup(null)}
                                centered
                                size="lg">
                                <Modal.Header><Modal.Title>{t("group")}</Modal.Title></Modal.Header>
                                <Modal.Body>
                                    <div className="form-group row">
                                        <div className='col-lg-6' key='name'>
                                            <div className='form-group'>
                                                <label htmlFor='name'>{t('name')}</label>
                                                <Field className='form-control' type="text" name="name" />
                                                <ErrorMessage name="name" component="div" className="formik-error-message" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6' key='receivers'>
                                            <div className='form-group'>
                                                <label htmlFor='receivers'>{t('recipient')}</label>
                                                <ReactMultiSelectCheckboxes
                                                    className='form-control multiselect-checkbox'
                                                    name="receivers"
                                                    isClearable={true}
                                                    value={groupSeleccionado.receivers}
                                                    multiple={true}
                                                    width={'100%'}
                                                    placeholder={t("enterAValue...")}
                                                    placeholderButtonLabel={t('selectOneOption')}
                                                    getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                                                        return value && value.length > 0 ? `${value.length} ítems seleccionados` : placeholderButtonLabel;
                                                    }}
                                                    onChange={(value, event) => onChangeMultiselect(value, event, values)}
                                                    options={[{ label: t('all'), value: "*" }, ...receiversList]}
                                                >
                                                </ReactMultiSelectCheckboxes>
                                                {errors.receivers && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block formik-error-message'>{errors.receivers}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                                        handleReset();
                                        togglePopupGroup(null);
                                    }}>
                                        <i className="fa fa-times" />{" " + t('cancel')}
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-sm m-1" disabled={isSubmitting}
                                        onClick={() => handleSubmit()}>
                                        <i className="fa fa-save" />{" " + t('save')}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={popupEliminarGroup}
                                onHide={() => setPopupEliminarGroup(false)}
                                centered
                                size="lg">
                                <Modal.Header><Modal.Title>Confirmación</Modal.Title></Modal.Header>
                                <Modal.Body>
                                    <label>{t('confirmDeleteGroup')}</label>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="m-1 btn btn-sm" onClick={() => setPopupEliminarGroup(false)}>
                                        <i className="fa fa-times" />{" " + t('cancel')}
                                    </button>
                                    <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => deleteGroup()}>
                                        <i className="fa fa-save" />{" " + t('confirm')}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
                </Formik>
            </React.Fragment>
        </div >
    );
}

export { Group };