import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import ReactSlidingPane from 'react-sliding-pane';

type FilterProps = {
  revState: boolean[],
  voltageLevel: boolean[],
  issueCount: number
}
const initialValues = {
  revState: [true, true, true, true, true],
  voltageLevel: [true, true, true, true],
  issueCount: 0
}
const SlideFilterPane: React.FC<any> = ({ isOpen = false, onChange, handleFilterChange }) => {

    const [open, setOpen] = useState(isOpen);
    //filtros
    const [filtros, setFiltros] = useState<FilterProps>(initialValues);

    const { t } = useTranslation();

    useEffect(() => {
      setOpen(isOpen)
    }, [isOpen])

    const setRevState = (index, valor) => {
      const revs = [
        ...filtros.revState
      ];
      revs[index] = valor;

      const filtrosState = {
        ...filtros,
        revState: revs
      }

      setFiltros(filtrosState);
      handleFilterChange(filtrosState);
    }

    const setIssueCount = (valor) => {
      const filtrosState = {
        ...filtros,
        issueCount: parseInt(valor)
      }

      setFiltros(filtrosState);
      handleFilterChange(filtrosState);
    }

    const setVoltageLevel = (index, valor, voltage) => {
      const vs = [
        ...filtros.voltageLevel
      ];
      vs[index] = valor;

      const filtrosState = {
        ...filtros,
        voltageLevel: vs
      }

      setFiltros(filtrosState)
      handleFilterChange(filtrosState);
    }

    return(
        <ReactSlidingPane
          className="map-slider-pane filter-slider-pane p-0"
          overlayClassName="custom-filter-overlay-class"
          isOpen={open}
          from="right"
          width="500px"
          title={t('switchingControls')}
          onRequestClose={() => {
            setOpen(false);
            onChange(false);
          }}
        >
          <React.Fragment>
            <div className='list-group list-group-accent'>
              {/** RIESGOS */}
              <div className='list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small'>
                {t('revisions')}
              </div>
              <div className="list-group-item list-group-item-accent-info list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>{t('unreviewed')}</strong></label>
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-info" checked={filtros.revState[0]} onChange={(event) => setRevState(0, event.target.checked)} />
                      </div>                      
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-success list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                    <label className="form-check-label"><strong>{t('noIncidents')}</strong></label>
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-success" checked={filtros.revState[1]} 
                          onChange={(event) => setRevState(1, event.target.checked)} />
                      </div>
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-warning list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>{t('lowRisk')}</strong></label>                      
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-warning" checked={filtros.revState[2]} onChange={(event) => setRevState(2, event.target.checked)} />
                      </div>
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-orange list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>{t('mediumRisk')}</strong></label>                      
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-orange" checked={filtros.revState[3]} onChange={(event) => setRevState(3, event.target.checked)} />
                      </div>
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-danger list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>{t('highRisk')}</strong></label>                      
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-danger" checked={filtros.revState[4]} onChange={(event) => setRevState(4, event.target.checked)} />
                      </div>
                  </div>
              </div>

              {/** CANTIDAD DE INCIDENCIAS */}
              <div className='list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small'>
                {t('accountIncidents')}
              </div>
              <div className="list-group-item list-group-item-accent-success list-group-item-divider item-filter-aside">
                <div className="clearfix d-flex align-items-end">
                  <label className="form-check-label my-auto" style={{minWidth: '250px'}}><strong>{t('minimumNumberOfIncidents')}</strong></label>
                    <div className="ms-auto">
                      <input type="number" className="form-control" 
                        name='issueCount'
                        min={0}
                        placeholder='1'
                        value={filtros.issueCount}
                        onClick={(event) => event.preventDefault()}
                        onChange={(event) => setIssueCount(event.target.value)} 
                      />
                    </div>
                </div>
              </div>

              {/** VOLTAGE */}
              <div className='list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small'>
                {t('voltageLevels')}
              </div>
              <div className="list-group-item list-group-item-accent-success list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>132 kV</strong></label>                      
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-success" checked={filtros.voltageLevel[0]} onChange={(event) => setVoltageLevel(0, event.target.checked, '132')} />
                      </div>
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-success list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>33 kV</strong></label>                      
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-success" checked={filtros.voltageLevel[1]} onChange={(event) => setVoltageLevel(1, event.target.checked, '33')} />
                      </div>
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-success list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>13,2 kV</strong></label>                      
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-success" checked={filtros.voltageLevel[2]} onChange={(event) => setVoltageLevel(2, event.target.checked, '13,2')} />
                      </div>
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-success list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>220 kV</strong></label>                      
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-success" checked={filtros.voltageLevel[3]} onChange={(event) => setVoltageLevel(3, event.target.checked, '220')} />
                      </div>
                  </div>
              </div>
            </div>

            
          </React.Fragment>
        </ReactSlidingPane>
    )
}

export { SlideFilterPane }