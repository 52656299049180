import { AuthorizedRoute, PermissionsProvider } from '@tshio/react-router-permissions'
import React from 'react'
import { useSelector } from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import { RootState } from '../../../setup'
import { authorizationStrategy, getRoles, loginAuthorizationStrategy, ROLE, RouteProps } from '../../routing/auth/roles'
import { ReportConfig } from './components/ReportConfig'
import { ReportExport } from './components/ReportExport'
import { ReportGeneral } from './components/ReportGeneral'
import { ReportGeneralLine } from './components/ReportGeneralLine'
import { ReportHistoricLine } from './components/ReportHistoricLine'
import { ReportIssuesLine } from './components/ReportIssuesLine'
import { ReportList } from './components/ReportList'

const routes: RouteProps[] = [
  { path: '/report/list', component: ReportList, role: ROLE.ROLE_OPERATOR },
  { path: '/report/general', component: ReportGeneral },
  { path: '/report/general-line', component: ReportGeneralLine },
  { path: '/report/historic', component: ReportHistoricLine },
  { path: '/report/issues', component: ReportIssuesLine },
  { path: '/report/export', component: ReportExport, role: ROLE.ROLE_OPERATOR },
  { path: '/report/config', component: ReportConfig, role: ROLE.ROLE_ADMIN },
]

const ReportsPage: React.FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user)
  
  return (
    <Switch>
      <PermissionsProvider permissions={getRoles(user.role)} authorizationStrategy={authorizationStrategy}>
        {
          routes.map((route, idx) => (
            route.role ? 
            <AuthorizedRoute key={idx} path={route.path} requires={route.role}>
              {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="/error/401" />)}
            </AuthorizedRoute> 
            :
            <AuthorizedRoute key={idx} path={route.path} authorizationStrategy={loginAuthorizationStrategy}>
              {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="/error/401" />)}
            </AuthorizedRoute>
          ))
        }
      </PermissionsProvider>
    </Switch>
  )
}

export default ReportsPage
