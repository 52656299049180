import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const TWS = `${REACT_APP_API_URL}/tws/`

export function searchTWSData(startDate, endDate) {
  const filters = {
    startDate: startDate,
    endDate: endDate,
    startHour: '00:00:00',
    endHour: '23:59:00'
}

  return axios.post<{result: any}>(TWS + 'search', filters);
}

export function searchTWSDetails() {
  return axios.get<{result: any}>(TWS + 'search-details');
}