export const MASCARAS = {
  coordenada: /^(-?\d{1,2})+(\.\d{4,6})/,
  soloLetrasEspacio: /^[a-zA-Záéíóú -]+$/,
  soloLetrasNumeros: /^[a-zA-Záéíóú0-9]+$/,
  soloLetrasEspacioNumeros: /^[a-zA-Záéíóú0-9 -]+$/,
  password: /^[a-zA-Z0-9]{8,}$/,
  soloNumeros: /^[0-9]+$/,
  soloLetrasNumerosCaracteres: /^[a-zA-Záéíóú0-9!.#_]+$/,
}

export function redondearA2Digitos(numero) {
  return Math.round(Math.round(numero * 1000) / 10) / 100;
};

export function redondearA1Digito(numero) {
  return Math.round(numero * 10) / 10;
};

export function etiquetasMultiSelect(t) {
  return {
    "selectSomeItems": t('all'),
    "allItemsAreSelected": t('allItemsSelected'),
    "selectAll": t('selectAll'),
    "search": t('search...'),
    "clearSearch": t('clearSearch')
  }
}

export function ordenarList(campo, lista, sentido) {
  var comparer = function (a, b) {
    if (sentido === 'asc') {
      return (a[campo] > b[campo]) ? 1 : -1;
    }
    else if (sentido === 'desc') {
      return (a[campo] < b[campo]) ? 1 : -1;
    }
  }
  lista.sort(comparer);
  return lista;
}

export function ordenarListaPorMultiplesCampos(campo1, campo2, lista) {
  var comparer = function (a, b) {
    if(a[campo1] && b[campo1] && a[campo2] && b[campo2]) {    
      return a[campo1].localeCompare(b[campo1]) || a[campo2].localeCompare(b[campo2]);
    }
    return 0;
  }

  lista.sort(comparer);
  return lista;
}