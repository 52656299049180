import React, { useEffect } from "react";
import { getLines } from "../../map/redux/MapCRUD";
import { useTranslation } from "react-i18next";
import { SlideLinePane } from "../../map/components/SlideLinePane";
import { SlideZonePane } from "../../map/components/SlideZonePane";
import { SearchBar } from "../../map/components/SearchBar";
import { SlideFilterPane } from "./SlideFilterPane";
import { MapPage } from "./Map";
import moment from "moment";
import ReactSlidingPane from "react-sliding-pane";

const filterInitialValues = {
  type: 'NOT LOCKED',
  timeUnit: 'DAY',
  startDate: moment().startOf('month').format("YYYY-MM-DD"),
  endDate: moment().endOf('month').format("YYYY-MM-DD"),
  lines: []
}
const stateInitialValues = {
  isPaneOpenFilter: false,
  isPaneOpenZones: false,
  isPaneOpenDetails: false,
  isPaneOpenLines: false,
  isPaneOpenKPI: false
}
const TWSContainer = () => {

    const [state, setState] = React.useState({
        isPaneOpenFilter: false,
        isPaneOpenZones: false,
        isPaneOpenDetails: false,
        isPaneOpenLines: false,
        isPaneOpenKPI: false
      });
      const [filter, setFilter] = React.useState(filterInitialValues);
      const [kpiData, setKpiData] = React.useState([]);
    
      const { t } = useTranslation();
    
      useEffect(() => {
        getLines() 
        .then((res) => {
          res.data.forEach(line => line.enableFilter = true);
          setFilter({
            ...filter,
            linesFilter: res.data,
            lines: res.data.map(l => l.id)
          })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
       
      const handleEnableZone = (zone, enable) => {
        const lineasZona = filter.linesFilter.filter(l => l.zone_id === zone.id).map(l => l.id);
    
        //habilito/deshabilito la linea
        let lineasFilter = [...filter.linesFilter];  
        lineasFilter.forEach(lf => {
          if(lineasZona.includes(lf.id)) {
            lf.enableFilter = enable
          }
        });
    
        //set filtro
        let lineas = lineasFilter.filter(l => l.enableFilter).map(l => l.id);  
        setFilter({
          ...filter,
          linesFilter: lineasFilter,
          lines: lineas
        });    
      }
    
      const handleEnableLine = (line, enable) => {
        let lineas = [...filter.linesFilter];  
        const index = lineas.findIndex(l => l.id === line.id);
        lineas[index].enableFilter = enable;
        
        setFilter({
          ...filter,
          linesFilter: lineas,
          lines: lineas.filter(l => l.enableFilter).map(l => l.id)
        })
      }
    
      const handleFilterChange = (filters) => {
        setFilter({
          ...filter,
          ...filters
        })
      }
    
    return (
        <div className='map-wrapper'>
            <div className='d-flex flex-row flex-column-fluid'>
              <button className='btn btn-sm btn-primary min-width-100 border-white' onClick={() => setState({ ...stateInitialValues, isPaneOpenZones: !state.isPaneOpenZones })}>
                  <i className="fa fa-sitemap"></i>{" "}{t('ZONES')}
              </button>
              <button className='btn btn-sm btn-primary min-width-100 border-white' onClick={() => setState({ ...stateInitialValues, isPaneOpenLines: !state.isPaneOpenLines })}>
                  <i className="fa fa-chart-line"></i>{" "}{t('LINES')}
              </button>
              <button className='btn btn-sm btn-primary min-width-100 border-white' onClick={() => setState({ ...state, isPaneOpenKPI: !state.isPaneOpenKPI })}>
                  <i className="fa fa-tachometer-alt"></i>{" "}{t('kpis')}
              </button>

              <SearchBar></SearchBar>

              <button className='btn btn-sm btn-primary min-width-100 border-white' onClick={() => setState({ ...stateInitialValues, isPaneOpenFilter: !state.isPaneOpenFilter })}>
                  <i className="fa fa-filter" />{"  "} {t('filters')}
              </button>
              <SlideZonePane 
                  isOpen={state.isPaneOpenZones} 
                  onChange={(open) => setState({...state, isPaneOpenZones: open})} 
                  handleEnableZone={(zone, enable) => handleEnableZone(zone, enable)}>
              </SlideZonePane>
              <SlideLinePane 
                  isOpen={state.isPaneOpenLines} 
                  linesData={filter.linesFilter}
                  onChange={(open) => setState({...state, isPaneOpenLines: open})} 
                  handleEnableLine={(line, enable) => handleEnableLine(line, enable)}>
              </SlideLinePane> 
              <SlideFilterPane
                  isOpen={state.isPaneOpenFilter}
                  onChange={(open) => setState({...state, isPaneOpenFilter: open})} 
                  handleFilterChange={(filters) => handleFilterChange(filters)}>
              </SlideFilterPane>
              <ReactSlidingPane
                className="map-slider-pane kpi-sliding-pane p-0"
                overlayClassName="kpi-sliding-pane-overlay"
                isOpen={state.isPaneOpenKPI}
                title={t('totalsTWS')}
                from="bottom"
                onRequestClose={(open) => setState({...state, isPaneOpenKPI: open})}>
                <div className="d-flex flex-row-fluid justify-content-between">
                    {
                        kpiData.map((total, index) => 
                          <div className="card m-0" key={`total_${index}`}>
                              <div className="card-header">
                                  <h4 className="card-title text-center"><strong>{t(total.label)}</strong></h4>
                              </div>
                              <div className="card-body p-2">
                                  <h3 className="text-center">{total.value}</h3>
                              </div>
                          </div>
                        )
                    }
                </div>
              </ReactSlidingPane>
            </div>
            <MapPage 
              filter={filter}
              setKpiData={(data) => setKpiData(data)}>
            </MapPage>
        </div>
    )
}

export { TWSContainer };