import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const GET_ISSUES_PENDING = `${REACT_APP_API_URL}/issue/pending`
export const WORK_ORDERS = `${REACT_APP_API_URL}/order/`

export function getIssuesPending() {
  return axios.get<{result: any}>(GET_ISSUES_PENDING)
}

export function getWorkOrders(status?) {
  return axios.get<{result: any}>(WORK_ORDERS + 'find?status=' + status)
}

export function printWorkOrderReport(data) {
  return axios.post<{result: any}>(WORK_ORDERS + 'print-resumen', data, { responseType: 'blob' })
}

export function saveWorkOrders(data) {
  return axios.post<{result: any}>(WORK_ORDERS + 'save-all', data)
}

export function updateWorkOrders(data) {
  return axios.post<{result: any}>(WORK_ORDERS + 'update-all', data)
}

export function cancelWorkOrder(workOrderId) {
  return axios.get<{result: any}>(WORK_ORDERS + 'cancel/' + workOrderId)
}

export function sendEmailWorkOrders(data) {
  return axios.post<{result: any}>(WORK_ORDERS + 'send-email', data)
}