import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const USER = `${REACT_APP_API_URL}/user/`

export function getUserByUsername(username) {
  return axios.get<{result: any}>(USER + 'find?username=' + username);
}

export function saveUser(data) {
  return axios.post<{result: any}>(USER, data);
}

export function loginUser(data) {
  return axios.post<{result: any}>(USER + 'login', data);
}
