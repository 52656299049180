import React, {useEffect, useState} from 'react'
import { Card } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import ReactSlidingPane from 'react-sliding-pane';
import { getZones } from '../../reports/redux/ReportsCRUD';

const SlideZonePane: React.FC<any> = ({ isOpen = false, onChange, handleEnableZone }) => {

    const [open, setOpen] = useState(isOpen);
    const [enableAllZones, setEnableAllZones] = useState(true);
    const [zones, setZones] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
      setOpen(isOpen)
    }, [isOpen])

    useEffect(() => {
      getZones().then((res: any) => {
        res.data.forEach(zone => zone.enableFilter = true);
        setZones(res.data);
      });
    }, [])

    const toggleEnableAllZones = (enable) => {
      const zonas = [...zones];
      zonas.forEach(zone => {
        zone.enableFilter = enable;
        handleEnableZone(zone, enable);
      });

      setEnableAllZones(enable);
      setZones(zonas);
    }

    const toggleEnableZone = (zone, index, enable) => {
      const zonas = [...zones];
      zonas[index].enableFilter = enable;
      handleEnableZone(zone, enable);
      setZones(zonas);
    }

    return(
        <ReactSlidingPane
          className="map-slider-pane zone-sliding-pane p-0"
          overlayClassName="custom-overlay-class"
          isOpen={open}
          from="left"
          width="500px"
          title={t('ZONES')}
          onRequestClose={() => {
            setOpen(false);
            onChange(false);
          }}
        >
          <React.Fragment>
            <Card.Header className="p-2" style={{ height: '40px' }}>
                <div className="clearfix">
                  <div className="form-check form-switch">
                    <input type="checkbox" className="form-check-input form-check-success" checked={enableAllZones} onChange={(event) => toggleEnableAllZones(event.target.checked)} />
                    <label className="form-check-label ms-3"><strong>{t('showAllZones')}</strong></label>
                  </div>
                </div>
            </Card.Header>
            {
              zones.map((zone, index) => (
                <Card.Header key={zone.id} className="p-2" style={{ height: '40px' }}>
                  <div className="clearfix">
                    <div className="form-check form-switch">
                      <input type="checkbox" className="form-check-input" checked={zone.enableFilter} onChange={(event) => toggleEnableZone(zone, index, event.target.checked)} />
                      <label className="form-check-label ms-3"><strong>{zone.name}</strong></label>
                    </div>
                  </div>
                </Card.Header>
              ))
            }
          </React.Fragment>
        </ReactSlidingPane>
    )
}

export { SlideZonePane }