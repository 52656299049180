import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import ReactSlidingPane from 'react-sliding-pane';
import moment from "moment";
import HorizontalTimeline from 'react-horizontal-timeline';
var chunk = require('chunk-date-range');

type FilterProps = {
  type: string,
  startDate: string,
  endDate: string,
  timeUnit: string,
  range: any
}
const initialValues = {
  type: 'NOT LOCKED',
  timeUnit: 'DAY',
  startDate: moment().startOf('month').format("YYYY-MM-DD"),
  endDate: moment().endOf('month').format("YYYY-MM-DD"),
  range: {
    min: 0,
    max: 1,
    step: 1,
    value: 0,
    marks: ['2022-01-01', '2022-02-01']
  },
}
const SlideFilterPane: React.FC<any> = ({ isOpen = false, onChange, handleFilterChange }) => {

    const [open, setOpen] = useState(isOpen);
    //filtros
    const [filtros, setFiltros] = useState<FilterProps>(initialValues);

    const { t } = useTranslation();

    useEffect(() => {
      setOpen(isOpen)
    }, [isOpen])

    const setFilterValue = (valor, property) => {
      const filtrosState = {
        ...filtros
      }
      filtrosState[property] = valor;

      if(property === 'range') {
        const index = valor.value;

        let startDate = moment(filtrosState.range.marks[index]).format('YYYY-MM-DD');
        let endDate = moment(filtrosState.range.marks[index]).format('YYYY-MM-DD');

        if(filtrosState.timeUnit !== 'DAY') {
            if(index === 0) {
                //si es 0, toma desde principio de mes
                startDate = moment(filtrosState.range.marks[0]).format('YYYY-MM-DD');
            }

            if((index + 1) === filtrosState.range.marks.length) {
                //si es el ultimo, toma hasta fin del rango
                endDate = moment(filtrosState.endDate).format('YYYY-MM-DD');
            }
            else {
                endDate = moment(filtrosState.range.marks[index+1]).format('YYYY-MM-DD');
            }
        }

        handleFilterChange({
          ...filtrosState,
          startDate: startDate,
          endDate: endDate
        });
      }
      else {
        setFiltros(filtrosState);
        handleFilterChange(filtrosState);
        
        updateMinMaxRange(true, true, filtrosState);
      }
    }

    const updateMinMaxRange = (updateMax, updateValue, filtros) => {
      const marks = [];

      const timeUnit = filtros.timeUnit;
      let startDate = moment(filtros.startDate);
      let endDate = moment(filtros.endDate);

      const chunks = chunk(startDate, endDate, timeUnit.toLowerCase());
      const total = chunks.length;
      chunks.forEach((range, index) => {
          if(index !== 0 || total === 1) {
              marks.push(range.start);
          }
      });

      const range = filtros.range;
      range.marks = marks;
      range.step = 1;
      if(updateMax) {
          range.max = total;
      }
      if(updateValue) {
          range.value = 0;
      }

      const filtrosState = {...filtros};
      filtrosState.range = range;
      setFiltros(filtrosState);
      handleFilterChange(filtrosState);
    }
 
    return(
        <ReactSlidingPane
          className="map-slider-pane filter-slider-pane p-0"
          overlayClassName="custom-filter-overlay-class"
          isOpen={open}
          from="right"
          width="500px"
          title={t('switchingControls')}
          onRequestClose={() => {
            setOpen(false);
            onChange(false);
          }}
        >
          <React.Fragment>
            <div className='list-group list-group-accent'>
              {/** TIPO */}
              <div className='list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small'>
                {t('revisions')}
              </div>
              <div className="list-group-item list-group-item-accent-warning list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>{t('notLocked')}</strong></label>
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-warning" checked={filtros.type === 'NOT LOCKED'} 
                          onChange={() => setFilterValue('NOT LOCKED', 'type')} />
                      </div>                      
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-orange list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                    <label className="form-check-label"><strong>{t('lockedNormal')}</strong></label>
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-orange" checked={filtros.type === 'LOCKED NORMAL'} 
                          onChange={() => setFilterValue('LOCKED NORMAL', 'type')} />
                      </div>
                  </div>
              </div>
              <div className="list-group-item list-group-item-accent-danger list-group-item-divider item-filter-aside">
                  <div className="clearfix d-flex justify-content-between">
                      <label className="form-check-label"><strong>{t('lockedHighPriority')}</strong></label>                      
                      <div className="form-check form-switch ms-auto">
                        <input type="checkbox" className="form-check-input form-check-danger" checked={filtros.type === 'LOCKED HIGH PRIORITY'} 
                          onChange={() => setFilterValue('LOCKED HIGH PRIORITY', 'type')} />
                      </div>
                  </div>
              </div>

              {/** FECHAS */}
              <div className='list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small'>
                {t('dates')}
              </div>
              <div className="list-group-item list-group-item-accent-secondary list-group-item-divider item-filter-aside">
                <div className="row">
                  <div className='col-lg-6'>
                    <label className='form-label'>{t('startDate')}</label>
                    <input
                      className={"form-control"}
                      type="date"
                      name="startDate"
                      placeholder="dd/mm/yyyy"
                      value={filtros.startDate}
                      onChange={(event) => {
                        setFilterValue(event.target.value, 'startDate')
                      }}
                    />
                  </div>
                  <div className='col-lg-6'>
                  <label className='form-label'>{t('endDate')}</label>
                    <input
                      className={"form-control"}
                      type="date"
                      name="endDate"
                      placeholder="dd/mm/yyyy"
                      value={filtros.endDate}
                      onChange={(event) => {
                        setFilterValue(event.target.value, 'endDate')
                      }}
                    />
                  </div>
                </div>
              </div>

              {/** time unit */}
              <div className='list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small'>
                {t('timeUnit')}
              </div>
              <div className='d-flex'>
                <button style={{ width: '100%' }} className={"btn btn-sm btn-" + (filtros.timeUnit === 'DAY' ? "success" : "primary")}  
                  onClick={() => setFilterValue('DAY', 'timeUnit')}>{t('day', 'timeUnit')}
                </button>
                <button style={{ width: '100%' }} className={"btn btn-sm btn-" + (filtros.timeUnit === 'WEEK' ? "success" : "primary")}  
                  onClick={() => setFilterValue('WEEK', 'timeUnit')}>{t('week', 'timeUnit')}
                </button>
                <button style={{ width: '100%' }} className={"btn btn-sm btn-" + (filtros.timeUnit === 'MONTH' ? "success" : "primary")}  
                  onClick={() => setFilterValue('MONTH', 'timeUnit')}>{t('month', 'timeUnit')}
                </button>
              </div>

              {/** time unit */}
              <div className='list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small'>
                {t('selectRange')}
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div>
                    <div style={{ width: '100%', height: '100px', margin: '0 auto' }}>
                      <HorizontalTimeline
                        index={filtros.range.value}
                        values={filtros.range.marks} 
                        indexClick={(index) => {
                          const range = filtros.range;
                          range.value = index;
                          setFilterValue(range, 'range');
                        }}
                        minEventPadding={10}
                        maxEventPadding={10}
                        linePadding={40}
                        getLabel={(date, index)  => { 
                            if(filtros.timeUnit === 'DAY') {
                                return moment(date).format('DD/MM');
                            } 
                            else if(filtros.timeUnit === 'WEEK'){
                                return 'Semana ' + (index + 1); 
                            }
                            else if(filtros.timeUnit === 'MONTH'){
                                return 'Mes ' + (index + 1); 
                            }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </ReactSlidingPane>
    )
}

export { SlideFilterPane }