import React from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import '../../assets/dashboard.scss';

const TablaIncidenciasDetalleLinea = (props) => {

  const { campo, columnas, historial, onIrA, onClickIncidenciaLinea, loading } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Breadcrumb>
        {historial.map((item, i) => (
          <BreadcrumbItem key={i} active={i === historial.length - 1}>
            {i === historial.length - 1 ? (
              item
            ) : (
              <button type="button" className="link-button" disabled={loading} onClick={e => onIrA(e, i)}>
                {t(item)}
              </button>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <table className="table dashboard-table table-bordered table-responsive">
        <thead>
          <tr>
            {columnas.map((columna, i) => {
              return (
                <th key={i} className="text-center">
                  {
                    columna.split('\\n').map((item, i) => <p style={{ marginBottom: 0 }} key={i}>{t(item)}</p>)
                  }
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {campo.filas.length > 0 ? (
            campo.filas.map((fila, i) => {
              return (
                <tr key={i}>
                  <td style={{ width: '220px', minWidth: '220px' }}>{fila.firstColumn}</td>
                  {
                    !fila.data ? null :
                      fila.data.map((detail, i) => {
                        return (
                          <td key={i} style={{ cursor: 'pointer' }}
                            className={detail.levelRisk ? detail.levelRisk : 'NORMAL'}
                            //disabled={loading}
                            onClick={e => i >= 1 && onClickIncidenciaLinea(e, fila, columnas[i + 1])}>
                            {detail.count}
                          </td>
                        )
                      })
                  }
                  <td>
                    {fila.percentRevision + '%'}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td style={{ textAlign: "center" }} colSpan={columnas.length}>
                {t('noData')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default TablaIncidenciasDetalleLinea;
