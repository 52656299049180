/* eslint-disable eqeqeq */
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import { getLines, getTowersLine } from "../../../reports/redux/ReportsCRUD";
import { createStructureRevision } from "./helper";
import * as Yup from "yup";
import { getRevisionNumberForLine } from "../../redux/UploadsCRUD";

type Props = {
    open: boolean;
    onClose: () => void;
    onConfirm: (data) => void;
}
const ModalCreateRevision = (props: Props) => {
    const { open, onClose, onConfirm } = props;

    const [openModal, setOpenModal] = useState(open);
    const [lines, setLines] = useState([]);    
    const [structuresList, setStructuresList] = useState([]);
    const [revisionNumber, setRevisionNumber] = useState();

    const { t } = useTranslation();

    useEffect(() => {
      setOpenModal(open);
    }, [open]);

    useEffect(() => {
        getLines().then((res: any) => setLines(res.data));
    }, []);

    const handleSelectLine = (lineSelected) => {
        if (lineSelected) {
            const body = {
                line_id: lineSelected
            }
    
            getTowersLine(body)
            .then((res: any) => {
                //se ordenan las torres por su orden en la linea
                let towersData = res.data;
                towersData = towersData.map((tower, index) => {
                    const result = {
                        ...tower,
                        order: index
                    };
                    const towerLine = tower.lines.filter(tl => tl.line_id === lineSelected);
                    if (towerLine.length) {
                        result.order = towerLine[0].order;
                    }
                    return result;
                })
                towersData = towersData.sort((t1, t2) => t1.order - t2.order);
                setStructuresList(towersData);
            });

            getRevisionNumberForLine(lineSelected)
            .then((res: any) => {
                if (res.data && res.data.revisionNumber) {
                    setRevisionNumber(res.data.revisionNumber)
                }
            })
        }
    };

    const RevSchema = Yup.object().shape({
        lineId: Yup.number().required(t('campoRequerido')),
        startDate: Yup.date().required(t('campoRequerido')),
        endDate: Yup.date().required(t('campoRequerido')),
    });

    return(
        <Formik
            initialValues={{
                lineId: '',
                startDate: '',
                endDate: '',
                revNumber: '',
            }}
            validationSchema={RevSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (revisionNumber && values) {
                    const lineRevision = lines.filter(l => l.id == values.lineId)[0];
                    const structuresData = structuresList.map(structure => (createStructureRevision(structure, [], values.startDate)));
                    const data = {
                        lines,
                        lineRevision,
                        structuresList,
                        structuresRevisionList: structuresData,
                        startDate: values.startDate,
                        endDate: values.endDate,
                        numberRevision: revisionNumber,
                    };
                    onConfirm(data);
                }
            }}
        >
            {({ values, isSubmitting, handleChange, handleSubmit, isValid }) => (
                <Modal show={openModal} className={"modal-info"} size="lg" centered onHide={() => onClose()} >
                    <Modal.Header>
                        <Modal.Title>Crear revisión</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column flex-wrap">
                            <div className="form-group">
                                <label htmlFor={'line'}>
                                    {t('line')}
                                </label>
                                <select
                                    className="form-select"
                                    name='lineId'
                                    value={values.lineId}
                                    onChange={e => {
                                        handleChange(e);
                                        handleSelectLine(e.target.value);
                                    }}
                                >
                                    <option>{t('selectOneOption')}</option>
                                    {
                                        lines.map(line => (
                                            <option key={line.id} value={line.id}>{line.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='start-date'>{t('startDate')}</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    placeholder='dd/mm/aaaa'
                                    name="startDate"
                                    value={values.startDate}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='end-date'>{t('endDate')}</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    placeholder='dd/mm/aaaa'
                                    name="endDate"
                                    value={values.endDate}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='rev-number'>{t('revisionNumber')}</label>
                                <input
                                    className='form-control'
                                    type="number"
                                    placeholder='99'
                                    value={revisionNumber}
                                    readOnly
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary btn-sm"  onClick={() => onClose()}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary btn-sm" onClick={() => handleSubmit()} disabled={!isValid}>
                            Confirmar
                        </button>{" "}
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    )
}
export default ModalCreateRevision;