import { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MASCARAS } from "../../../core/FuncionesCampos";
import { mostrarError, mostrarExito } from "../../../core/EmisorMensajes";
import { useTranslation } from "react-i18next";
import * as auth from '../auth/redux/AuthRedux'
import { UserModel } from "../auth/models/UserModel";
import { RootState } from "../../../setup";
import { getUserByUsername, loginUser, saveUser } from "./redux/ProfileCRUD";

const ProfilePage = () => {
    const [user, setUser] = useState(null);
    const [cambioPasswordMode, setCambioPasswordMode] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userData: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

    const campos = {
        name: {
            label: "name",
            placeholder: "",
            tipo: "text",
            ancho: 6,
            autoFocus: true,
            visible: true
        },
        surname: {
            label: "surname",
            placeholder: "",
            tipo: "text",
            ancho: 6,
            visible: true
        },
        role: {
            label: "role",
            placeholder: "",
            tipo: "text",
            ancho: 4,
            soloLectura: true,
            visible: true
        },
        email: {
            label: "email",
            placeholder: "",
            tipo: "email",
            ancho: 4,
            visible: true
        },
        username: {
            label: "username",
            placeholder: "",
            tipo: "text",
            ancho: 4,
            soloLectura: true,
            visible: true
        },
        actualPassword: {
            label: "currentPassword",
            placeholder: "",
            tipo: "password",
            ancho: 4,
            cambioPasswordMode: true
        },
        newPassword1: {
            label: "newPassword",
            placeholder: "",
            tipo: "password",
            ancho: 4,
            cambioPasswordMode: true
        },
        newPassword2: {
            label: "newPasswordRepeat",
            placeholder: "",
            tipo: "password",
            ancho: 4,
            cambioPasswordMode: true
        }
    }

    // Validation schema
    const ProfileSchema = Yup.object().shape({
        name: Yup.string().required(t('campoRequerido')).matches(MASCARAS.soloLetrasEspacio, t('soloLetrasEspacio')),
        surname: Yup.string().required(t('campoRequerido')).matches(MASCARAS.soloLetrasEspacio, t('soloLetrasEspacio')),
        role: Yup.string().required(t('campoRequerido')),
        username: Yup.string().required(t('campoRequerido')),
        email: Yup.string().optional().email(t('emailFormatInvalid')),
        
        actualPassword: Yup.string().min(8).matches(MASCARAS.soloLetrasNumerosCaracteres, t('soloLetrasNumerosCaracteres')),
        newPassword1: Yup.string().min(8).matches(MASCARAS.soloLetrasNumerosCaracteres, t('soloLetrasNumerosCaracteres')),
        newPassword2: Yup.string().min(8).matches(MASCARAS.soloLetrasNumerosCaracteres, t('soloLetrasNumerosCaracteres')),
    });

    useEffect(() => {
        refreshData();
        // eslint-disable-next-line
    }, []);

    const refreshData = () => {
        getUserByUsername(userData.username)
          .then(res => {
            if(res.data){
                const user = res.data;
                setUser(user);
            }
          })
          .catch(err => {
            mostrarError(err);
          });
    }

    const saveUserPerfil = (formData) => {
        if(cambioPasswordMode === true) {
            //comparo si las contraseñas coinciden
            if(formData.newPassword1 === formData.newPassword2) {
                //verifico si la contraseña actual es correcta
                loginUser({ username: formData.username, password: formData.actualPassword })
                .then((res: any) => {          
                    if(res && res.data && res.data.username) {
                        actualizarUsuario(formData.newPassword1, formData)
                    }
                    else {
                        mostrarError("La contraseña actual no es válida");
                    }
                })
                .catch(err => { 
                    mostrarError("La contraseña actual no es válida");
                });
            }
            else {
                mostrarError("Las contraseñas no coinciden");
            }
        }       
        else {
            actualizarUsuario(false, formData);
        }

        setUser(formData);
    };

    const actualizarUsuario = (password, formData) => {
        if(password) {
            formData.passwordModificada = password;
        }

        delete formData['password'];
        delete formData['actualPassword'];
        delete formData['newPassword1'];
        delete formData['newPassword2'];
        saveUser(formData)
        .then(res => {
            if(res.status === 200){
                mostrarExito(t("changesApplied"));
                if(password) {
                    setTimeout(() => {
                        dispatch(auth.actions.logout());
                        window.location.reload();
                    }, 2000);
                }
            }
        })
        .catch(err => {
            mostrarError(err);
        });     
    }

    return (
        <div className="animated fadeIn">
            <div className="card">
                <div className="card-header">
                    <div className="card-title">{t('profile')}</div>
                </div>
                <div className="card-body">
                    {!user ? null : 
                    <Formik
                        initialValues={user}
                        validationSchema={ProfileSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                saveUserPerfil(values);
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ values, isSubmitting, handleChange, handleSubmit }) => (
                        <form>
                            <div className="form-group row">
                                {//Listamos todos los campos que declaramos en el estado
                                Object.keys(campos).filter(indice => campos[indice].visible).map(indice => {
                                    return (
                                        <div className={"col-lg-" + campos[indice].ancho} key={indice}>
                                            <div className="form-group">
                                                <label htmlFor={indice}>
                                                    {t(campos[indice].label)} {campos[indice].obligatorio ? "*" : ""}
                                                </label>
                                                <input
                                                    className='form-control'
                                                    name={indice}
                                                    type={campos[indice].tipo}
                                                    value={values[indice]}
                                                    onChange={handleChange}
                                                    readOnly={campos[indice].soloLectura}
                                                    placeholder={campos[indice].placeholder}
                                                />
                                                <ErrorMessage name={indice} component="div" className="form-error-message" />
                                            </div>
                                        </div>
                                    );
                                })
                                }
                                <div className="col-lg-12 mt-10 mb-10">
                                    <div className="form-group checkbox mb-2">
                                        <input type="checkbox" className="form-check-input" onChange={e => setCambioPasswordMode(e.target.checked)} />
                                        <label className="ms-2">{t('changePassword')}</label>
                                    </div>
                                </div>
                                {
                                !cambioPasswordMode ? null : 
                                <>
                                  {
                                    Object.keys(campos).filter(indice => campos[indice].cambioPasswordMode).map(indice => {
                                      return (
                                          <div className={"col-lg-" + campos[indice].ancho}>
                                              <div className="form-group">
                                                  <label htmlFor={indice}>
                                                      {t(campos[indice].label)} {campos[indice].obligatorio ? "*" : ""}
                                                  </label>
                                                  <Field
                                                      name={indice}
                                                      className='form-control'
                                                      type={campos[indice].tipo}
                                                      value={values[indice]}
                                                      onChange={handleChange}
                                                      readOnly={campos[indice].soloLectura}
                                                      placeholder={campos[indice].placeholder}
                                                  />
                                                  <ErrorMessage name={indice} component="div" className="form-error-message" />
                                              </div>
                                          </div>
                                      );
                                    })
                                  }
                                  <div className="col-lg-12"><label style={{ fontStyle: 'italic' }}>{t('passwordValidation')}</label></div>
                                </>
                                }
                            </div>
                            <div className="form-group row mt-10">
                                <div className="col-lg-12">
                                    <button type="button" className="btn btn-primary btn-lg m-1" disabled={isSubmitting} onClick={() => handleSubmit()}>
                                        <i className="fa fa-save" />{" "+t('save')}
                                    </button>
                                </div>
                            </div>
                        </form>
                        )}
                    </Formik>
                    }
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;
