import { AuthorizedRoute, PermissionsProvider } from '@tshio/react-router-permissions'
import React from 'react'
import { useSelector } from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import { RootState } from '../../../setup'
import { authorizationStrategy, getRoles, loginAuthorizationStrategy, RouteProps } from '../../routing/auth/roles'
import { TWSAlertsData } from './components/TWSAlertsData'
import { TWSContainer } from './components/TwsContainer'
import { TWSExportData } from './components/TWSExportData'
import { TWSROCAlertGeneration } from './components/TWSROCAlertGeneration'

const routes: RouteProps[] = [
  { path: '/tws/map', component: TWSContainer },
  { path: '/tws/alerts', component: TWSROCAlertGeneration },
  { path: '/tws/report', component: TWSAlertsData },
  { path: '/tws/data', component: TWSExportData },
]

const TWSPage: React.FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user)
  
  return (
    <Switch>
      <PermissionsProvider permissions={getRoles(user.role)} authorizationStrategy={authorizationStrategy}>
        {
          routes.map((route, idx) => (
            route.role ? 
            <AuthorizedRoute key={idx} path={route.path} requires={route.role}>
              {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="/error/401" />)}
            </AuthorizedRoute> 
            :
            <AuthorizedRoute key={idx} path={route.path} authorizationStrategy={loginAuthorizationStrategy}>
              {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="/error/401" />)}
            </AuthorizedRoute>
          ))
        }
      </PermissionsProvider>
    </Switch>
  )
}

export default TWSPage
