import React, {FC, useState} from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { Charts } from './components/Charts'
import { Table } from './components/Table'

const DashboardWrapper: FC = () => {
  const { t } = useTranslation();
  const [filtro, setFiltro] = useState({
    date_start: '2023-01-01',
    date_end: '2023-12-31',
    anio: 2023
  });

  const handleCambiarAño = (anio) => {
    setFiltro({
      anio,
      date_start: `${anio}-01-01`,
      date_end: `${anio}-12-31`
    })
  }

  return (
    <>
      <div className='card-header'>
        <div className='d-flex flex-row justify-content-between'>
          <label className='fs-3'>
            {t('performanceIndicatorsBetween')}{" "}
              <strong>
                <Moment format={"DD/MM/YYYY"}>{filtro.date_start}</Moment>{" "}-{" "} 
                <Moment format={"DD/MM/YYYY"}>{filtro.date_end}</Moment>
              </strong>
          </label>
          <div>
            {[2023, 2022, 2021, 2020, 2019].map(anio => {
              return ( 
                <button key={anio} type="button" className={"btn btn-sm btn-" + (filtro.anio === anio ? "primary" : "secondary")} onClick={() => handleCambiarAño(anio)}>
                  {anio}
                </button>
              )
            })}
          </div>
        </div>
      </div>
      <Charts filtro={filtro}></Charts>
      <Table filtro={filtro}></Table>
    </>
  )
}

export default DashboardWrapper
