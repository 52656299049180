export const VOLTAGE_LEVEL = [
    '132',
    '33',
    '13,2',
    '220'
  ]
export const LEVEL_RISK = [
    'NO_REVISION',
    'NORMAL',
    'LOW_RISK',
    'MEDIUM_RISK',
    'HIGH_RISK'
]

