import React from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import "../../assets/dashboard.scss";

const TablaIncidenciasZona: React.FC<any> = (props) => {

  const { campo, columnas, historial, onClickZona, onClickTipoIncidencia, loading } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Breadcrumb>
        {historial.map((item, i) => (
          <BreadcrumbItem key={i} active={i === historial.length - 1}>
            {t(item)}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <table className="table dashboard-table table-bordered table-responsive">
        <thead>
          <tr className="bg-light align-items-center">
            {columnas.map((columna, i) => {
              return (
                <th key={i}  className="text-center">
                  {columna !== 'Sin incidencia' ? 
                  <button type="button" className="link-button" disabled={loading} onClick={e => onClickTipoIncidencia(e, columna)}>
                  {
                    columna.split('\\n').map((item, i) => <p style={{marginBottom: 0}} key={i}>{t(item)}</p>)
                  }
                  </button> 
                  :
                  <span>{t('Sin incidencia')}</span>
                  }
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody >
          {campo.filas.length > 0 ? (
            campo.filas.map((fila, i) => {
              return (
                <tr key={i}>
                  <td>
                    <button type="button" className="link-button" disabled={loading} onClick={e => onClickZona(e, fila)}>
                      {fila.firstColumn}
                    </button>
                  </td>
                  {
                    !fila.data ? null :
                    fila.data.map((detail, i) => {
                      return(
                        <td key={i} className={detail.levelRisk ? detail.levelRisk : 'NORMAL'}>
                          {detail.count}
                        </td>
                      )
                    })
                  }
                  <td>
                    {fila.percentRevision + '%'}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td style={{ textAlign: "center" }} colSpan={columnas.length}>
                {t('noData')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default TablaIncidenciasZona;
