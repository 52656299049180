import {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { mostrarInfo } from "../../../../core/EmisorMensajes";
import { getPeriodsList } from "../../../../core/FuncionesFechas";
import { getReportDataLineZone, getZones } from "../redux/ReportsCRUD";
import { ReportDetail } from "./ReportDetail";
import LoadingOverlay from 'react-loading-overlay-ts';

const initialValues = {
    startDate: '', endDate: '', period: 'ONE_YEAR', zone_id: undefined, general: true, zone_name: ''
}

const ReportGeneral : FC = () => {
    const [loading, setLoading] = useState('');
    const [filter, setFilter] = useState<any>(initialValues);
    const [reportData, setReportData] = useState<any>(null);
    const [zonesList, setZonesList] = useState<any[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        getZones()
        .then((res: any) => {
            setZonesList(res.data);
        })
    }, [])

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const search = () => {
        const periodValid = (filter.startDate && filter.endDate && filter.startDate < filter.endDate);
        if(!periodValid && !filter.period) {
            mostrarInfo(t('errorPeriodInvalid'));
            return;
        }

        setLoading('Cargando datos');
        getReportDataLineZone(filter)
        .then(res => {
            if(res && res.data){
                setReportData(res.data);
                setLoading('');
            }
        });
    }

    return (
        <>
            <LoadingOverlay active={loading !== ''} spinner text={loading + '...'}>
            <div className="card">
                <div className="card-body">
                    <h3>{t('generalReport')}</h3>
                    <form>
                        <div className="form-group row">
                            <div className='col-lg-4' key='start-date'>
                                <div className='form-group'>
                                    <label htmlFor='start-date'>{t('startDate')}</label>
                                    <input
                                        className='form-control'
                                        type="date"
                                        placeholder='dd/mm/aaaa'
                                        name="startDate"
                                        value={filter.startDate}
                                        onChange={(e) => setFilter({
                                            ...filter,
                                            period: '',
                                            startDate: e.target.value
                                        })}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4' key='end-date'>
                                <div className='form-group'>
                                    <label htmlFor='end-date'>{t('endDate')}</label>
                                    <input
                                        className='form-control'
                                        type="date"
                                        placeholder='dd/mm/aaaa'
                                        name="endDate"
                                        value={filter.endDate}
                                        onChange={(e) => setFilter({
                                            ...filter,
                                            period: '',
                                            endDate: e.target.value
                                        })}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4' key='period'>
                                <div className='form-group'>
                                    <label htmlFor='end-date'>{t('period')}</label>
                                    <select
                                        className='form-select'
                                        name="period"
                                        value={filter.period}
                                        onChange={(e) => setFilter({
                                            ...filter,
                                            period: e.target.value
                                        })}
                                    >
                                        <option value="">{t('selectOneOption')}</option>
                                        {
                                            getPeriodsList(t).map(period => (
                                                <option value={period.id} key={period.id}>{period.descripcion}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    {
                        !reportData ? <></> : 
                        <div className="mt-5">
                            {
                            reportData.map((group, index) => (
                                <div className="card" key={index}>
                                    <div className="card-header bg-secondary minh-50">
                                        <div className="d-flex flex-row-fluid justify-content-between ">
                                                <div className="card-title my-0">{filter.zone_name ? filter.zone_name : t('allZones')}</div>
                                                <div className="card-title my-0">
                                                    <select className="form-select" 
                                                        value={filter.zone_id}
                                                        onChange={(e) => {
                                                            const zonas = zonesList.filter(z => z.id === e.target.value);                                                            
                                                            setFilter({
                                                                ...filter,
                                                                zone_id: e.target.value,
                                                                zone_name: zonas.length ? zonas[0].name : t('allZones')
                                                            });
                                                        }}>
                                                        <option value=''>{t('allZones')}</option>
                                                        {zonesList.map(opcion => {
                                                            return (
                                                                <option key={opcion.id} value={opcion.id}>{opcion.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="card-body">
                                        <ReportDetail reportData={group}></ReportDetail>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    }
                </div>
            </div>
            </LoadingOverlay>
        </>
    );
}

export { ReportGeneral };