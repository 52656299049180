import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from "react-i18next";
import { searchData } from "../redux/MapCRUD";

const SearchBar: React.FC<any> = ({ onChangeSearchBar }) => {
    const [pattern, setPattern] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    let _cache = {};
    const { t } = useTranslation();

    useEffect(() => {
        if(pattern.length >= 2) {
            setTimeout(() => {
                handleSearch();
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pattern])

    const handleSearch = () => {
        if (_cache[pattern]) {
            setOptions(_cache[pattern].options);
            return;
        }

        setLoading(true);
        searchData(pattern)
        .then((resp: any) => {
            _cache[pattern] = { ...resp, page: 1 };
            setLoading(false);
            setOptions(resp.data);
        });
    }

    return (
        <AsyncTypeahead
            isLoading={loading}
            options={options}
            id="buscador"
            labelKey={(option: any) => `${option.type} - ${option.description}`}
            maxResults={100 - 1}
            minLength={1}
            onChange={(selected) => onChangeSearchBar(selected)}
            onInputChange={(value) => setPattern(value)}
            onSearch={() => {}}
            autoFocus
            ignoreDiacritics
            placeholder={t("searchPlaceholder")}
            promptText={(pattern.length >= 3) ? "Buscando..." : "Ingrese el nombre de la línea o código de estructura..."}
            searchText="Buscando..."
            emptyLabel="No hay resultados"
            useCache={true}
            style={{ width: '100%', height: '40px' }}
        />
    );
}

export { SearchBar };