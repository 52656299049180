const CryptoJS = require("crypto-js");
const REACT_APP_CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY
const API_URL = process.env.REACT_APP_API_URL

const getUserData = (store) => {
  const { auth: {accessToken} } = store.getState();
  if(accessToken) {
    const bytes = CryptoJS.AES.decrypt(accessToken, REACT_APP_CRYPTO_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalText);
  }
  return null;
}

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json, text/plain, */*';
  axios.interceptors.request.use(
    (config: any) => {
      /*
      const userData = getUserData(store);
      if (userData) {
        config.headers.Authorization = `${userData.token}`
      }
      */
      const token = localStorage.getItem('token');
      config.headers.Authorization = `${token}`
      return config
    },
    (err: any) => Promise.reject(err)
  );
  axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
      const userData = getUserData(store);
      if (userData && userData.refreshToken) {
        axios.post(`${API_URL}/user/refresh-token`, { refreshToken: userData.refreshToken, username: userData.username })
        .then((res: any) => {
          if(res.data && res.data.token) {
            localStorage.setItem('token', res.data.token);
            window.location.reload();
          }
        }, 
        error => {
          console.error(error)
          localStorage.clear();
          window.location.href = '/';
        })
      }
      else {
        localStorage.clear();
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
});
}
