import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap-v5";
import { saveAs } from 'file-saver'
import { useTranslation } from "react-i18next";
import { uuid } from 'uuidv4';
import { deleteMedia, listFilesMedia } from "../redux/UploadsCRUD";
import { mostrarError, mostrarExito } from "../../../../core/EmisorMensajes";

const GalleryMedia: React.FC  = () => {
    const [popupEliminarMedia, setPopupEliminarMedia] = useState(false);
    const [mediaSeleccionado, setMediaSeleccionado] = useState(null);
    const [tokensPages, setTokensPages] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [folderSelected, setFolderSelected] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        listMediaFiles(0, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateTokensList = (page, token) => {
        const tokens = [...tokensPages];
        tokens.push({ page, token });
        setTokensPages(tokens);
    }

    const getTokenIndex = (index) => {
        if(index === 0) {
            return '';
        }
        const page = tokensPages.filter(t => t.page === index);
        if(page.length) {
            return page[0].token;
        }
        return '';
    }

    const listMediaFiles = (page, folder) => {
        listFilesMedia(getTokenIndex(page), 24, folder)
        .then((res: any) => {
            if(res && res.data){
                setPageIndex(page);
                setMediaFiles(res.data.Contents);
                setFolders(res.data.CommonPrefixes);
                updateTokensList(page+1, res.data.NextContinuationToken);
            }
        });
    }

    const downloadFileByName = (mediaFile) => {
        saveAs(mediaFile.publicUrl, mediaFile.Key, { target: "_blank" });
    }

    const deleteMediaByName = () => {
        if(mediaSeleccionado) {
            deleteMedia(mediaSeleccionado.Key)
            .then((res: any) => {
                if (res.status === 200) {
                    mostrarExito(t('changesApplied'));
                    setPopupEliminarMedia(false);
                    setMediaSeleccionado(null);
                    listMediaFiles(pageIndex, folderSelected);
                }
                else {
                    mostrarError(t(res.data));
                }
            })
            .catch(err => {
                if (err?.response?.data) {
                    mostrarError(t(err.response.data));
                }
                else {
                    mostrarError(err);
                }
            });
        }
    }

    return (
        <div>
            <Card>
                <Card.Header>
                    <Card.Title><strong className="title-upload">{t('galleryMedia')}</strong></Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                    {
                        folderSelected ? 
                        <div className="d-flex justify-content-start p-0">
                            <button className="btn btn-secondary btn-md me-5" onClick={() => {
                                setFolderSelected('');
                                listMediaFiles(0, '');
                            }}>
                                <div className="fa fa-arrow-left pe-3"></div>
                                Volver a la carpeta raíz
                            </button>
                        </div>
                        : <></>
                    }
                    {
                        folders.map(folder => (
                            <div className="col-lg-2 mt-5" key={uuid()}>
                                <Card className="border border-dark">
                                    <Card.Body className="p-0">
                                        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '200px' }}>
                                            <button className="btn btn-md" onClick={() => {
                                                setFolderSelected(folder.Prefix);
                                                listMediaFiles(0, folder.Prefix);
                                            }}>
                                                <div className="fa fa-folder me-2"></div> {folder.Prefix}
                                            </button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))
                    }
                    {
                        mediaFiles.map(mediaFile => (
                            <div className="col-lg-2 mt-5">
                                <Card className="border border-primary">
                                    <Card.Body className="p-0">
                                        <img className="rounded-top" width="100%" height="200px"
                                            src={mediaFile.publicUrl}  alt={mediaFile.Key}/>
                                    </Card.Body>
                                    <Card.Footer className="p-0">
                                        <div className="d-flex flex-row justify-content-end">
                                            <button className="btn btn-sm btn-outline-dark" onClick={() => downloadFileByName(mediaFile)}>
                                                <div className="fa fa-download"></div>
                                            </button>
                                            <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                setMediaSeleccionado(mediaFile);
                                                setPopupEliminarMedia(true);
                                            }}>
                                                <div className="fa fa-trash"></div>
                                            </button>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div>
                        ))
                    }
                    {
                        tokensPages.length ? 
                        <div className="d-flex justify-content-center mt-5">
                            {
                                pageIndex !== 0 ? 
                                <button className="btn btn-secondary btn-md me-5" onClick={() => {
                                    listMediaFiles(pageIndex-1, folderSelected);
                                }}>
                                    <div className="fa fa-arrow-left pe-3"></div>
                                    Anterior</button>
                                : <></>
                            }
                            {
                                getTokenIndex(pageIndex+1) ? 
                                <button className="btn btn-secondary btn-md" onClick={() => {
                                    listMediaFiles(pageIndex+1, folderSelected);
                                }}>
                                    Siguiente
                                    <div className="fa fa-arrow-right ps-3"></div>
                                </button>
                                : <></>
                            }
                            
                        </div> : <></>
                    }
                    </div>
                </Card.Body>
            </Card>
            <Modal
                show={popupEliminarMedia}
                onHide={() => setPopupEliminarMedia(false)}
                centered
                size="lg">
                <Modal.Header><Modal.Title>Confirmación</Modal.Title></Modal.Header>
                <Modal.Body>
                    <label>{t('confirmDeleteMedia')}</label>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="m-1 btn btn-sm" onClick={() => setPopupEliminarMedia(false)}>
                        <i className="fa fa-times" />{" " + t('cancel')}
                    </button>
                    <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => deleteMediaByName()}>
                        <i className="fa fa-save" />{" " + t('confirm')}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
 
export { GalleryMedia };