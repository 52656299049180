import React, {useEffect, useState} from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { searchTWSDetails } from "../redux/TwsCRUD";

const TWSExportData = () => {
    const [filas, setFilas] = useState([]);
    const { t } = useTranslation();
    const _export = React.useRef(null);

    useEffect(() => {
        searchTWSDetails()
        .then(res => {
            setFilas(res.data);
        })
    }, []);
 
    const exportar = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (_export.current !== null) {
            _export.current.save();
        }
    }

    return(
        <div>
            <form action="" method="post" className="form-horizontal">
                <div className="card mb-0">
                    <div className="card-header"><div className="card-title">{t('dataQuery')}</div></div>
                    <div className="p-2">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-success btn-sm pull-right" onClick={(e) => exportar(e)}>
                                <i className="fa fa-file-excel"></i>{" "}
                                {t('exportExcel')}
                            </button>
                        </div>
                        {
                            !filas ? null :
                            <React.Fragment>
                                <ReactDataGrid
                                    className="ot-table"
                                    rowKey='id'
                                    style={{height: '83vh', overflowY: 'auto'}}
                                    columns={[
                                        { name: "date", header: t('date'), render: ({ data }) => <Moment format={"DD/MM/YYYY"}>{data.date}</Moment>, defaultFlex: 1 },
                                        { name: "hour", header: t('hour'), defaultFlex: 1 },
                                        { name: "tower_name", header: t('structure'), defaultFlex: 1 },
                                        { name: "type", header: t('failureType'), defaultFlex: 1 },
                                    ]}
                                    dataSource={filas}
                                />
                                <ExcelExport
                                    data={filas}
                                    fileName="TWS_data.xlsx"
                                    ref={_export}>
                                    <ExcelExportColumn field={"date"} title={t('date')}/>
                                    <ExcelExportColumn field={"hour"} title={t('hour')}/>
                                    <ExcelExportColumn field={"tower_name"} title={t('structure')} />
                                    <ExcelExportColumn field={"type"} title={t('failureType')} />
                                </ExcelExport>
                            </React.Fragment>
                        }
                    </div>
                </div>

            </form>
        </div>
    )
}
 
export { TWSExportData };