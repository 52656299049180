import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar } from 'react-chartjs-2';
import { getBacklogKPI } from '../../redux/DashboardCRUD';

const ChartBacklog: React.FC<any> = ({ filter, modal }) => {
  const { t } = useTranslation();
  const [dataKPI, setDataKPI] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if(filter) {
      getBacklogKPI(filter)
      .then((res: any) => {
        const valores = res.data.map(d => d.count);
        const noCero = valores.filter(v => v !== '0');
        
        if(noCero.length) {
          setLabels(res.data.map(d => t(d.tipo)))
          setDataKPI(valores);
        }
        else {
          setDataKPI(null);
          setLabels([]);
        }
      })
    }
  }, [filter, t])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5' style={{minHeight: '40px'}}>
        <label className='fw-bolder mx-auto' style={{ fontSize: modal ? '20px' : '12px', marginBottom: modal ? '1.25rem' : '' }}>{t('BACKLOG')}</label>
      </div>

      <div className='card-body pt-0' style={{ height: modal ? '450px' : '200px' }}>
        {
          dataKPI ? 
          <Bar 
            data={{
              labels: labels,
              datasets: [
                {
                  data: dataKPI,
                  backgroundColor: [
                    'rgb(10,73,123)',
                    'rgb(255,0,0)',
                  ],
                  hoverBackgroundColor: [
                    'rgb(10,73,123)',
                    'rgb(255,0,0)',
                  ],
                  borderWidth: 1
                }
              ],
            }}
            options={{ 
              indexAxis: 'y',
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            }}            
          />
          : <div style={{ marginTop: '25%' }}>
              <h5 className='text-center'>{t('noData')}</h5>
            </div>
        }
      </div>
    </div>
  )
}

export { ChartBacklog };