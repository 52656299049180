import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import { mostrarError, mostrarExito } from "../../../../core/EmisorMensajes";
import { uploadStructures } from "../redux/UploadsCRUD";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { i18nTable } from "../../../../core/Funciones";

const UploadFileStructure: React.FC  = () => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [resultadoUpload, setResultadoUpload] = useState(null);
    const [filasResultado, setFilasResultado] = useState([]);
    const inputFile = useRef<any>();

    const { t } = useTranslation();

    const subirFichero = () => {

        if(file == null) {
            mostrarError('Debe seleccionar un fichero')
        }

        setFilasResultado([]);
        setUploading(true);

        if(file) {
            let fileForm = new FormData();
            fileForm.append("file", file);
            
            uploadStructures(fileForm)
            .then((res: any) => {
                if(res.data){
                    if(res.data.upload) {
                        mostrarExito(t('fileUploaded'));
                    }
                    else if(res.data.errors){
                        let errores = '';
                        res.data.errors.forEach((errorRow, index) => {
                            if(errorRow.length) {
                                errorRow.forEach(errorDetail => {
                                    errores += `Hoja: ${index+1}, Fila: ${errorDetail.row}, Columna: ${errorDetail.column}, Descripción: ${errorDetail.error}\n`
                                })
                            }
                            else{ 
                                errores += `Fila: ${errorRow.row}, Columna: ${errorRow.column}, Descripción: ${errorRow.error}\n`
                            }
                        })
                        setResultadoUpload(errores);
                    }
                    setFilasResultado(res.data.details);
                    setUploading(false);
                    setFile(null);
                    
                    if(inputFile && inputFile.current) {
                        inputFile.current.value = '';
                    }
                }
            })
            .catch(err => {
                setUploading(false);
                setFile(null);
                if(err.message === 'Network Error') {
                    mostrarError(t('errorFileModified'));
                }
                else {
                    mostrarError(t('errorUploadFile'));
                }
            });
        }
    }

    return (  
        <div>
            <Card>
                <Card.Header>
                    <Card.Title><strong className="title-upload">{t('uploadStructures')}</strong></Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='form-group'>
                                <label htmlFor='file'>{t('file')}</label>
                                <input 
                                    ref={inputFile}
                                    className='form-control'
                                    type="file"
                                    accept=".xls, .xlsx"
                                    placeholder={t('selectOneFile')}
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <ReactDataGrid
                                idProperty="id"
                                style={{ minHeight: 150, height: '42vh', overflowY: 'auto' }}
                                columns={[
                                    { name: "type", header: t('type'), defaultFlex: 1 },
                                    { name: "object", header: t('object'), defaultFlex: 1 },
                                    { name: "line", header: t('line'), defaultFlex: 1 },
                                    { name: "tower", header: t('structure'), defaultFlex: 1 },
                                    { name: "errorMessage", header: t('errorMessage'), defaultFlex: 1 },
                                ]}
                                dataSource={filasResultado}
                                i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='form-group'>
                                <label htmlFor='file'>{t('result')}</label>
                                <textarea
                                    className='form-control'
                                    rows={3}
                                    value={resultadoUpload}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <button 
                        type="button" className="btn btn-sm btn-success m-1" disabled={uploading} onClick={() => subirFichero()}>
                        <i className="fa fa-check" /> {t('uploadFile')}
                    </button>
                    <a download href="/files/plantilla-estructura.xlsx" className="m-1 btn btn-sm">
                        <i className="fa fa-download" /> {t('downloadTemplate')}
                    </a>
                </Card.Footer>
            </Card>
        </div>
    );
}
 
export { UploadFileStructure };