import { AuthorizedRoute, PermissionsProvider } from '@tshio/react-router-permissions'
import React from 'react'
import { useSelector } from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import { RootState } from '../../../setup'
import { authorizationStrategy, getRoles, loginAuthorizationStrategy, ROLE, RouteProps } from '../../routing/auth/roles'
import {CreateWorkOrders} from './components/CreateWorkOrders'
import ManageWorkOrders from './components/ManageWorkOrders'
import { WorkOrderReport } from './components/ReportWorkOrder'
import { Teams } from './components/Teams'

const routes: RouteProps[] = [
  { path: '/wos/create', component: CreateWorkOrders, role: ROLE.ROLE_ADMIN },
  { path: '/wos/manage', component: ManageWorkOrders, role: ROLE.ROLE_ADMIN },
  { path: '/wos/teams', component: Teams, role: ROLE.ROLE_ADMIN },
  { path: '/wos/report', component: WorkOrderReport, role: ROLE.ROLE_OPERATOR },
]

const WorkOrdersPage: React.FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user)
  
  return (
    <Switch>
      <PermissionsProvider permissions={getRoles(user.role)} authorizationStrategy={authorizationStrategy}>
        {
          routes.map((route, idx) => (
            route.role ? 
            <AuthorizedRoute key={idx} path={route.path} requires={route.role}>
              {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="/error/401" />)}
            </AuthorizedRoute> 
            :
            <AuthorizedRoute key={idx} path={route.path} authorizationStrategy={loginAuthorizationStrategy}>
              {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="/error/401" />)}
            </AuthorizedRoute>
          ))
        }
      </PermissionsProvider>
    </Switch>
  )
}

export default WorkOrdersPage
