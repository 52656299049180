import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import ReactSlidingPane from 'react-sliding-pane';
import '../assets/styles.scss';

const initialValues = {
  total_issues: 0, high_risk: 0, scheduled: 0, total_orders: 0, done: 0, backlog: 0
}
const SlideKPIPane: React.FC<any> = ({ isOpen = false, data, onChange }) => {

    const [open, setOpen] = useState(isOpen);
    const [dataKPI, setDataKPI] = useState(initialValues);

    const { t } = useTranslation();

    useEffect(() => {
      setOpen(isOpen)
    }, [isOpen]);

    useEffect(() => {
      setDataKPI(data)
    }, [data]);

    const calcularPorcentaje = (a, b) => {
      if(a && b && a !== '0' && b !== '0'){
          return ((a/b)*100).toFixed(1);
      }
      return '-';
  }

    return(
        <ReactSlidingPane
          className="map-slider-pane kpi-sliding-pane p-0"
          overlayClassName="kpi-sliding-pane-overlay"
          isOpen={open}
          from="bottom"
          width="500px"
          title={t('indicatorDetails')}
          onRequestClose={() => {
            setOpen(false);
            onChange(false);
          }}
        >
          <React.Fragment>
            {
              dataKPI ?
              <div className='d-flex flex-row-fluid justify-content-between'>
                <div className='card m-0'>
                  <div className='card-header'>
                    <div className='card-title text-center'><strong>{t('totalIncidents')}</strong></div>
                  </div>
                  <div className='card-body p-2'>
                    <h3 className="text-center">{dataKPI.total_issues}</h3>
                  </div>
                </div>
                <div className='card m-0'>
                  <div className='card-header'>
                    <div className='card-title text-center'><strong>{t('highRisk')}</strong></div>
                  </div>
                  <div className='card-body p-2'>
                    <h3 className="text-center">({calcularPorcentaje(dataKPI.high_risk, dataKPI.total_issues)})%</h3>
                  </div>
                </div>
                <div className='card m-0'>
                  <div className='card-header'>
                    <div className='card-title text-center'><strong>{t('planned')}</strong></div>
                  </div>
                  <div className='card-body p-2'>
                    <h3 className="text-center">({calcularPorcentaje(dataKPI.scheduled, dataKPI.total_issues)})%</h3>
                  </div>
                </div>
                <div className='card m-0'>
                  <div className='card-header'>
                    <div className='card-title text-center'><strong>{t('wo')}</strong></div>
                  </div>
                  <div className='card-body p-2'>
                    <h3 className="text-center">{dataKPI.total_orders}</h3>
                  </div>
                </div>
                <div className='card m-0'>
                  <div className='card-header'>
                    <div className='card-title text-center'><strong>{t('complianceWO')}</strong></div>
                  </div>
                  <div className='card-body p-2'>
                    <h3 className="text-center">{dataKPI.done}</h3>
                  </div>
                </div>
                <div className='card m-0'>
                  <div className='card-header'>
                    <div className='card-title text-center'><strong>{t('backlogWO')}</strong></div>
                  </div>
                  <div className='card-body p-2'>
                    <h3 className="text-center">{dataKPI.backlog}</h3>
                  </div>
                </div>
              </div>
              : <></>
            }

          </React.Fragment>
        </ReactSlidingPane>
    )
}

export { SlideKPIPane }