import {Suspense} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import ReportsPage from '../modules/reports/ReportsPage'
import UploadsPage from '../modules/uploads/UploadsPage'
import WorkOrdersPage from '../modules/work-orders/WorkOrdersPage'
import { ConfigUsersWrapper } from '../pages/config-users/ConfigUsersWrapper'
import { SchedulerWrapper } from '../pages/scheduler/SchedulerWrapper'
import { authorizationStrategy, getRoles, loginAuthorizationStrategy, ROLE, RouteProps } from './auth/roles'
import { PermissionsProvider, AuthorizedRoute } from '@tshio/react-router-permissions';
import { useSelector } from 'react-redux'
import { RootState } from '../../setup'
import MapWrapper from '../modules/map/MapWrapper'
import DashboardWrapper from '../modules/dashboard/DashboardWrapper'
import ProfilePage from '../modules/profile/ProfilePage'
import { IconsWrapper } from '../pages/icons/IconsWrapper'
import TWSPage from '../modules/tws/TWSPage'

const routes: RouteProps[] = [
  { path: '/map', component: MapWrapper },
  { path: '/scheduler', component: SchedulerWrapper, role: ROLE.ROLE_OPERATOR },
  { path: '/dashboard', component: DashboardWrapper },
  { path: '/users', component: ConfigUsersWrapper, role: ROLE.ROLE_ADMIN },
  { path: '/profile', component: ProfilePage },
  { path: '/wos', component: WorkOrdersPage },
  { path: '/upload', component: UploadsPage },
  { path: '/report', component: ReportsPage },
  { path: '/tws', component: TWSPage },
  { path: '/icons', component: IconsWrapper },
]


export function PrivateRoutes() {
  
  const user: any = useSelector<RootState>(({auth}) => auth.user)

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <PermissionsProvider permissions={getRoles(user.role)} authorizationStrategy={authorizationStrategy}>
          {
            routes.map((route, idx) => (
              route.role ? 
              <AuthorizedRoute key={idx} path={route.path} requires={route.role}>
                {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="error/401" />)}
              </AuthorizedRoute> 
              :
              <AuthorizedRoute key={idx} path={route.path} authorizationStrategy={loginAuthorizationStrategy}>
                {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="error/401" />)}
              </AuthorizedRoute>
            ))
          }
        </PermissionsProvider>
      </Switch>
    </Suspense>
  )
}
