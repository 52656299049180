import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
const CryptoJS = require("crypto-js");

const API_URL = process.env.REACT_APP_API_URL
const REACT_APP_CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/user/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post(LOGIN_URL, {
    username: username,
    password: password,
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{result: boolean}>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  })
}

export function getUserByToken(accessToken?: any): any {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios

  if(accessToken) {
    const bytes = CryptoJS.AES.decrypt(accessToken, REACT_APP_CRYPTO_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalText);
  }
  return null;
}
