/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import { getLine, getResumenKPIMap, getStructure } from './redux/MapCRUD'
import { SlideZonePane } from './components/SlideZonePane';
import { useTranslation } from 'react-i18next';
import { SlideLinePane } from './components/SlideLinePane';
import { SlideFilterPane } from './components/SlideFilterPane';
import './assets/styles.scss'
import { SlideKPIPane } from './components/SlideKPIPane';
import { getLines } from '../reports/redux/ReportsCRUD';
import { MapPage } from './components/Map';
import { LEVEL_RISK, VOLTAGE_LEVEL } from './redux/const';
import { SlideDetailsPane } from './components/SlideDetailsPane';
import LoadingOverlay from 'react-loading-overlay-ts';
import { SearchBar } from './components/SearchBar';
import { useLocation } from 'react-router-dom';

const filterInitialValues = {
  revState: [true, true, true, true, true],
  voltageLevel: [true, true, true, true],
  issueCount: 0,
  lines: [],
  linesFilter: [],
  range: {
    min: 0,
    max: 1,
    step: 1,
    value: 0,
    marks: ['2022-01-01', '2022-02-01']
  },
}
const stateInitialValues = {
  isPaneOpenFilter: false,
  isPaneOpenZones: false,
  isPaneOpenDetails: false,
  isPaneOpenLines: false,
  isPaneOpenKPI: false
}
const MapWrapper: FC = () => {
  const [state, setState] = React.useState({
    isPaneOpenFilter: false,
    isPaneOpenZones: false,
    isPaneOpenDetails: false,
    isPaneOpenLines: false,
    isPaneOpenKPI: false
  });
  const [filter, setFilter] = React.useState(filterInitialValues);
  const [kpiData, setKpiData] = React.useState(null);
  const [structureData, setStructureData] = React.useState(null);
  const [loading, setLoading] = React.useState<any>('');
  const [lineData, setLineData] = React.useState(null);

  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if(location.state) {
      const state: any = location.state || { tower_id: null };
      if(state.tower_id) {
        handleClickTower(state.tower_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    getLines() 
    .then((res: any) => {
      res.data.forEach(line => line.enableFilter = true);
      setFilter({
        ...filter,
        linesFilter: res.data,
        lines: res.data.map(l => l.id)
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(filter) {
      const filterKpi = {
        voltage_level: VOLTAGE_LEVEL.filter((v, index) => filter.voltageLevel[index]),
        issue_count: filter.issueCount,
        level_risk: LEVEL_RISK.filter((v, index) => filter.revState[index]),
        lines: filter.lines,
    }
  
      getResumenKPIMap(filterKpi) 
      .then((res: any) => {
        setKpiData(res.data[0]);
      });
    }
  }, [filter]);

  const handleEnableZone = (zone, enable) => {
    const lineasZona = filter.linesFilter.filter(l => l.zone_id === zone.id).map(l => l.id);

    //habilito/deshabilito la linea
    let lineasFilter = [...filter.linesFilter];  
    lineasFilter.forEach(lf => {
      if(lineasZona.includes(lf.id)) {
        lf.enableFilter = enable
      }
    });

    //set filtro
    let lineas = lineasFilter.filter(l => l.enableFilter).map(l => l.id);  
    setFilter({
      ...filter,
      linesFilter: lineasFilter,
      lines: lineas
    });    
  }

  const handleEnableLine = (line, enable) => {
    let lineas = [...filter.linesFilter];  
    const index = lineas.findIndex(l => l.id === line.id);
    lineas[index].enableFilter = enable;
    
    setFilter({
      ...filter,
      linesFilter: lineas,
      lines: lineas.filter(l => l.enableFilter).map(l => l.id)
    })
  }

  const handleFilterChange = (filters) => {
    setFilter({
      ...filter,
      ...filters
    })
  }

  const handleClickTower = (id) => {

    setState({
      ...state,
      isPaneOpenZones: false,
      isPaneOpenKPI: false,
      isPaneOpenFilter: false,
      isPaneOpenLines: false,
      isPaneOpenDetails: true
    });

    setLoading('Cargando datos de estructura');
    getStructure(id)
    .then((res: any) => {
      setStructureData(res.data);
      setLineData({
        centerMap: { lat: parseFloat(res.data.latitude), lng: parseFloat(res.data.longitude) },
        zoomMap: 17,
        marker: res.data
      });
      setLoading('');
    })
  }

  const handleClickLine = (idLine) => {
    if(idLine) {
        getLine(idLine)
        .then((res: any) => {
            const indexTower = parseInt(res.data.towers.length) / 2;
            let middleTower = res.data.towers[indexTower];
            
            setLineData({
              centerMap: { lat: parseFloat(middleTower.tower.latitude), lng: parseFloat(middleTower.tower.longitude) },
              zoomMap: 12,
              marker: middleTower.tower
            });
        })
    }

    const lines = [...filter.linesFilter];
    const indexLine = lines.findIndex(l => l.id === idLine);
    if(indexLine !== -1) {
      lines[indexLine].enableFilter = true;
      lines[indexLine].details = true;
    };
    setFilter({
      ...filter,
      linesFilter: lines
    });
    setState({
      ...stateInitialValues,
      isPaneOpenLines: true
    })
  }

  const handleOnChangeSearchBar = (selected) => {
    if (selected.length) {
      const tipo = selected[0].type;
      const id = selected[0].id;

      if (tipo === "ESTRUCTURA") {
        handleClickTower(id);
      }
      else if (tipo === "LINEA") {
        handleClickLine(id);
      }
    }
  }

  return (
    <LoadingOverlay active={loading !== ''} spinner text={loading + '...'}>
      <div className='map-wrapper'>
        <div className='d-flex flex-row flex-column-fluid'>
          <button className='btn btn-sm btn-primary min-width-100 border-white' onClick={() => setState({ ...stateInitialValues, isPaneOpenZones: !state.isPaneOpenZones })}>
            <i className="fa fa-sitemap"></i>{" "}{t('ZONES')}
          </button>
          <button className='btn btn-sm btn-primary min-width-100 border-white' onClick={() => setState({ ...stateInitialValues, isPaneOpenLines: !state.isPaneOpenLines })}>
            <i className="fa fa-chart-line"></i>{" "}{t('LINES')}
          </button>
          <button className='btn btn-sm btn-primary min-width-100 border-white' onClick={() => setState({ ...state, isPaneOpenKPI: !state.isPaneOpenKPI })}>
            <i className="fa fa-tachometer-alt"></i>{" "}{t('kpis')}
          </button>

          <SearchBar onChangeSearchBar={(s) => handleOnChangeSearchBar(s)}></SearchBar>

          <button className='btn btn-sm btn-primary min-width-100 border-white' onClick={() => setState({ ...stateInitialValues, isPaneOpenFilter: !state.isPaneOpenFilter })}>
            <i className="fa fa-filter" />{"  "} {t('filters')}
          </button>
          <SlideDetailsPane 
            isOpen={state.isPaneOpenDetails} 
            onChange={(open) => setState({...state, isPaneOpenDetails: open})} 
            detailsData={structureData}>
          </SlideDetailsPane>
          <SlideZonePane 
            isOpen={state.isPaneOpenZones} 
            onChange={(open) => setState({...state, isPaneOpenZones: open})} 
            handleEnableZone={(zone, enable) => handleEnableZone(zone, enable)}>
          </SlideZonePane>
          <SlideLinePane 
            isOpen={state.isPaneOpenLines} 
            linesData={filter.linesFilter}
            onChange={(open) => setState({...state, isPaneOpenLines: open})} 
            handleEnableLine={(line, enable) => handleEnableLine(line, enable)}>
          </SlideLinePane>  
          <SlideFilterPane
            isOpen={state.isPaneOpenFilter}
            onChange={(open) => setState({...state, isPaneOpenFilter: open})} 
            handleFilterChange={(filters) => handleFilterChange(filters)}>
          </SlideFilterPane>
          <SlideKPIPane
            isOpen={state.isPaneOpenKPI}
            data={kpiData}
            onChange={(open) => setState({...state, isPaneOpenKPI: open})}>
          </SlideKPIPane>
        </div>
        <MapPage 
          filter={filter}
          handleClickTower={(id) => handleClickTower(id)}
          lineData={lineData}></MapPage>
      </div>
    </LoadingOverlay>
  )
}

export default React.memo(MapWrapper);
