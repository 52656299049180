import {useEffect, useState} from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { i18nTable } from "../../../../core/Funciones";

const TWSAlertsData = () => {
    const [filas, setFilas] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        setFilas([
            { date: '2021-05-01', alertType: 'ROC Line', line: 'Arrufo-San Guillermo', value: '3' },
            { date: '2021-05-02', alertType: 'ROC Line', line: 'Arrufo-San Guillermo', value: '3' },
            { date: '2021-05-05', alertType: 'ROC Estructura', line: 'Todas', value: '10' },
            { date: '2021-05-06', alertType: 'ROC Estructura', line: 'Todas', value: '5' },
            { date: '2021-05-06', alertType: 'ROC Estructura', line: '099-188', value: '1' },
        ]);
    }, []);
        
    return(
        <div>
            <form action="" method="post" className="form-horizontal">
                <div className="card mb-0">
                    <div className="card-header">
                        <div className="card-title">{t('ROCAlertReport')}</div>
                    </div>
                    <div className="card-body pt-2">
                        <div className="d-flex justify-content-end">
                            <div>
                                <button className="pull-right btn btn-success btn-sm">
                                    <i className="fa fa-file-excel"></i>{" "}
                                    {t('exportExcel')}
                                </button>
                            </div>
                        </div>
                        <ReactDataGrid
                            className="ot-table"
                            rowKey='id'
                            style={{height: '83vh', overflowY: 'auto'}}
                            columns={[
                                { name: "date", header: t('date'), defaultFlex: 1, render: ({ data }) => <Moment format={"DD/MM/YYYY"}>{data.date}</Moment> },
                                { name: "alertType", header: t('alertType'), defaultFlex: 1 },
                                { name: "line", header: t('line'), defaultFlex: 1 },
                                { name: "value", header: t('value'), defaultFlex: 1 },
                            ]}
                            dataSource={filas}
                            i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                        />
                    </div>
                </div>

            </form>
        </div>
    )
}
 
export { TWSAlertsData };