import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { eliminaReporte, getReports } from "../redux/ReportsCRUD";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { i18nTable } from "../../../../core/Funciones";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap-v5";
import { mostrarError, mostrarExito } from "../../../../core/EmisorMensajes";

const ReportList: FC = () => {
    const [reportsList, setReportsList] = useState<any[]>([]);
    const [popupConfirmacion, setPopupConfirmacion] = useState(false);
    const [reportSeleccionado, setReportSeleccionado] = useState(null);

    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        getReports('')
            .then((res: any) => {
                setReportsList(res.data);
            })
    }, [])

    const goToReport = (e, report) => {
        history.push("/report/general-line?id=" + report.id);
    }

    const deleteReport = (e, report) => {
        setReportSeleccionado(report);
        setPopupConfirmacion(true);
    }

    const confirmaDeleteReport = () => {
        if (reportSeleccionado) {
            eliminaReporte(reportSeleccionado.id)
                .then(res => {
                    if (res && res.data) {
                        mostrarExito("El reporte se ha eliminado correctamente");

                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        mostrarError(err.response.data);
                    }
                    else {
                        mostrarError(t("errorDefault"));
                    }
                    setPopupConfirmacion(false);
                });
        }
    }

    let ACCIONES = [
        {
            handler: goToReport, color: "info", icono: "fa fa-bullseye"
        },
        {
            handler: deleteReport, color: "danger", icono: "fa fa-trash"
        }
    ]

    return (
        <div className="card">
            <div className="card-body">
                <h3>{t('favoriteReports')}</h3>
                <ReactDataGrid
                    idProperty="id"
                    style={{ minHeight: 150, height: '85vh', overflowY: 'auto' }}
                    columns={[
                        { name: "name", header: t('name'), defaultFlex: 1 },
                        { name: "type", header: t('type'), defaultFlex: 1 },
                        { name: "owner", header: t('owner'), defaultFlex: 1 },
                        { name: "creation_date", header: t('creationDate'), defaultFlex: 1 },
                        {
                            name: 'acciones', header: t('actions'), defaultFlex: 1,
                            render: ({ data }) =>
                                <div className="p-0 text-center">
                                    {
                                        ACCIONES.map(accion =>
                                            <button className={'btn btn-sm btn-outline-' + accion.color} key={accion.handler + ''} onClick={() => accion.handler(null, data)}>
                                                <span className={accion.icono}></span>
                                            </button>
                                        )
                                    }
                                </div>
                        },
                    ]}
                    dataSource={reportsList}
                    i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                />
                <React.Fragment>
                    <Modal
                        show={popupConfirmacion}
                        onHide={() => setPopupConfirmacion(false)}
                        centered
                        size="lg">
                        <Modal.Header><Modal.Title>Confirmación</Modal.Title></Modal.Header>
                        <Modal.Body>
                            <label>Seguro que desea eliminar el reporte?</label>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="m-1 btn btn-sm" onClick={() => setPopupConfirmacion(false)}>
                                <i className="fa fa-times" />{" "+t('cancel')}
                            </button>
                            <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => confirmaDeleteReport()}>
                                <i className="fa fa-save" />{" " + t('confirm')}
                            </button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            </div>
        </div>
    );
}

export { ReportList };