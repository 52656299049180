import ReactDataGrid from '@inovua/reactdatagrid-community'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLines } from "../../map/redux/MapCRUD";

const TWSROCAlertGeneration = () => {
    const { t } = useTranslation();
    const [filas] = useState([
        { alertType: "ROC Estructura", quantity: "1", time: t('day'), line: t('all'), failureType: t('lockedHighPriority') },
        { alertType: "ROC Line", quantity: "1", time: t('hour'), line: t('all'), failureType: t('all') },
        { alertType: "ROC Line", quantity: "2", time: t('day'), line: 'Arrufo-San Guillermo', failureType: t('lockedNormal') }
    ]);
    const [campos] = useState({
        type: {
          label: "alertType",
          placeholder: "selectOneOption",
          tipo: "select",
          valido: null,
          contenido: "",
          feedback: "",
          opciones: [
            { id: "ROC Estructura", descripcion: t('ROC Estructura') },
            { id: "ROC Linea", descripcion: t('ROC Linea') }  
          ],
          ancho: 2,
          autoFocus: true
        },
        line: {
            label: "line",
            placeholder: "selectOneOption",
            tipo: "multiselect",
            valido: null,
            contenido: "",
            feedback: "",
            ancho: 3,
            modo: "TODOS",
            opciones: [],
            moneda: "",
            obligatorio: true,
            soloLectura: false
        },
        failureType: {
            label: "failureType",
            placeholder: "selectOneOption",
            tipo: "multiselect",
            valido: null,
            contenido: "",
            feedback: "",
            opciones: [
              { value: "1", label: t('outZone') },
              { value: "2", label: t('notLocked') },
              { value: "3", label: t('lockedNormal') },
              { value: "4", label: t('lockedHighPriority') }   
            ],
            ancho: 2,
            autoFocus: false
        },
        quantity: {
            label: "quantity",
            placeholder: "",
            tipo: "number",
            valido: null,
            contenido: "",
            feedback: "",
            ancho: 2,
            autoFocus: false
        },
        time: {
            label: "time",
            placeholder: "selectOneOption",
            tipo: "select",
            valido: null,
            opciones: [
                { id: "1", descripcion: t('hour') },
                { id: "2", descripcion: t('day') },
                { id: "3", descripcion: t('week') },
                { id: "4", descripcion: t('month') },
            ],
            contenido: "",
            feedback: "",
            ancho: 3,
            autoFocus: false
        },
    });
    const [lines, setLines] = useState([]);

    useEffect(() => {
        getLines()
        .then(linesList => {
            linesList.data.forEach(l => {
                l.label = l.name;
                l.value = l.id;
            });
            setLines(linesList.data);
        })
        .catch(err => {
            console.log(err);
        });
    }, [])

    return (
        <div>
            <form action="" method="post" className="form-horizontal">
                <div className="card">
                    <div className="card-header">
                        <div className='card-title'>{t('generalReport')}</div>
                    </div>
                    <div className="card-body">
                        {
                            //si el reporte no existe
                            <div className="form-group row" style={{ marginBottom: 0 }}>
                                {//Listamos todos los campos que declaramos en el estado
                                Object.keys(campos).map(indice => {
                                    if(indice === 'line') {
                                        return (
                                            <div className={'col-lg-' + campos[indice].ancho}>
                                                <label htmlFor={indice}>
                                                    {t(campos[indice].label)}
                                                </label>
                                                <select className="form-select"
                                                    key={indice}
                                                    indiceCampo={indice}
                                                    campo={campos[indice]}
                                                >
                                                    <option value={''}>{t('selectOneOption')}</option>
                                                    {
                                                        lines.map(line => (
                                                            <option value={line.value}>{line.label}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        );
                                    }
                                    else {
                                        return (
                                            <div className={'col-lg-' + campos[indice].ancho}>
                                                <label htmlFor={indice}>
                                                    {t(campos[indice].label)}
                                                </label>
                                                <input className="form-control"
                                                    key={indice}
                                                    indiceCampo={indice}
                                                    campo={campos[indice]}
                                                />
                                            </div>
                                        );
                                    }
                                })}
                                <div className="col-lg-12">
                                    <button
                                        type="button"
                                        style={{float: 'right'}}    
                                        className="m-1 btn btn-success btn-sm"
                                    >
                                        <i className="fa fa-save" /> {t('generateAlert')}
                                    </button>
                                </div>
                            </div>    
                        }
                        <div className="col-lg-12">
                            <h4>{t('generatedAlertsList')}</h4>
                        </div>
                        <div className='row'>
                            <ReactDataGrid 
                                rowKey='id'
                                style={{height: '70vh', overflowY: 'auto'}}
                                dataSource={filas}
                                columns={
                                    [
                                        { name: "alertType", header: t('alertType'), defaultFlex: 1 },
                                        { name: "quantity", header: t('quantity'), defaultFlex: 1 },
                                        { name: "time", header: t('time'), defaultFlex: 1 },
                                        { name: "line", header: t('line'), defaultFlex: 1 },
                                        { name: "failureType", header: t('failureType'), defaultFlex: 1  }
                                    ]
                                }
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export { TWSROCAlertGeneration };