import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSpeedometer from "react-d3-speedometer"
import { getMTTRKPI } from '../../redux/DashboardCRUD';
import { Overlay, Tooltip } from 'react-bootstrap-v5';

const ChartMTTR: React.FC<any> = ({ filter, modal }) => {
  const { t } = useTranslation();
  const [dataKPI, setDataKPI] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    if (filter) {
      getMTTRKPI(filter)
        .then((res: any) => {
          if (res.data) {
            setDataKPI(res.data);
          }
          else {
            setDataKPI(null);
          }
        })
    }
  }, [filter])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5' style={{ minHeight: '40px' }}>
        <label className='fw-bolder mx-auto' style={{ fontSize: modal ? '20px' : '12px', marginBottom: modal ? '1.25rem' : '' }}>{t('mttr')}</label>
      </div>
      <div className='card-body mb-0 pt-0' style={{ height: modal ? '300px' : '200px', paddingLeft: modal ? '17%' : '15%', paddingRight: modal ? '17%' : '12%' }}
        ref={target} onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
        {
          dataKPI ?
            <ReactSpeedometer
              minValue={0}
              maxValue={1}
              width={modal ? 500 : 275}
              dimensionUnit={'px'}
              //paddingHorizontal={modal ? 100 : 5}
              value={dataKPI}
              currentValueText={dataKPI.toFixed(2)}
              labelFontSize={modal ? '16px' : '12px'}
              startColor="#ff0000"
              ringWidth={modal ? 60 : 45}
              segments={100}
              maxSegmentLabels={1}
              endColor="#008f39"
            />
            :
            <div style={{ marginTop: '25%' }}>
              <h5 className='text-center'>{t('noData')}</h5>
            </div>
        }
      </div>
      <Overlay target={target.current} show={showTooltip} placement="right">
        {(props) => (
          <Tooltip id="tooltip-mttr" style={{ textAlign: 'left', padding: '10px' }} className='tooltip-mttr' {...props}>
            <div className='tooltip-mttr'>
              {t('middleTimeTooltip1')}<br />
              {t('middleTimeTooltip060')}<br />
              {t('middleTimeTooltip030')}<br />
              {t('middleTimeTooltip0')}
            </div>
          </Tooltip>
        )}
      </Overlay>

    </div>
  )
}

export { ChartMTTR };