import { useFormik } from "formik";
import {FC, useEffect, useState} from "react";
import { Modal } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import { mostrarError, mostrarExito, mostrarInfo } from "../../../../core/EmisorMensajes";
import { exportar } from "../../../../core/Funciones";
import { getPeriodsList } from "../../../../core/FuncionesFechas";
import { getLines, getReport, getReportDataLineZone, printReport, saveFavorite } from "../redux/ReportsCRUD";
import { ReportDetail } from "./ReportDetail";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

const initialValues = {
    startDate: '', endDate: '', period: 'ONE_YEAR', general: false, lines: []
}

const ReportGeneralLine : FC = () => {
    const [filter, setFilter] = useState<any>(initialValues);
    const [reportData, setReportData] = useState<any>([]);
    const [linesCompleteList, setLinesCompleteList] = useState<any[]>([]);
    const [linesList, setLinesList] = useState<any[]>([]);
    const [zonesList, setZonesList] = useState<any[]>([]);
    const [popupAgregarLinea, setPopupAgregarLinea] = useState(false);
    const [popupAgregarFav, setPopupAgregarFav] = useState(false);
    const [report, setReport] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    let history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');

        if(id){
            getReport(id)
            .then((res: any) => {
                if(res && res.data){
                    const data: any = res.data;
                    data.filterDateStart = res.data.filter_date_start;
                    data.filterDateEnd = res.data.filter_date_end;
                    setReport(data);
                    setFilter({
                        startDate: data.filterDateStart,
                        endDate: data.filterDateEnd,
                        lines: data.lines
                    })
                }
            });
        }
    }, [])

    useEffect(() => {
        getLines()
        .then((res: any) => {
            setLinesCompleteList(res.data);
            const zonas = res.data.map(line => line.zone);
            const opciones = Array.from(new Set(zonas.map(z => z.id)))
            .map(id => {
                return {
                    id,
                    descripcion: zonas.find(z => z.id === id).name,
                }
            });
            setZonesList(opciones)
        })
    }, [])

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const search = () => {
        const periodValid = (filter.startDate && filter.endDate && filter.startDate < filter.endDate);
        if(!periodValid && !filter.period) {
            mostrarInfo(t('errorPeriodInvalid'));
            return;
        }

        setLoading(true);
        getReportDataLineZone(filter)
        .then(res => {
            if(res && res.data){
                setReportData(res.data);
                setLoading(false);
            }
        });
    }

    const handlePrintPDF = () => {
        const data = {
            data: reportData
        }
        
        setLoading(true);
        printReport(data)
        .then(res => {
            if(res && res.data){
                exportar(res.data, 'report_line_details');
                setLoading(false);
            }
        });
    }

    const formikAgregarFav = useFormik<any>({
        initialValues: { name: '' },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setStatus();
            //SAVE
            const reportData = {
                idReport: 0,
                type: 'REVISION',
                lines: filter.lines,
                name: values.name,
                filterDateStart: filter.startDate,
                filterDateEnd: filter.endDate,
                filterDatePeriod: filter.period,
                owner: 'abei',
            };
            if(report) {
                reportData.idReport = report.id;
                reportData.type = report.type;
            }
    
            saveFavorite(reportData)
            .then(res => {
                if(res && res.data){
                  mostrarExito("El reporte se ha agregado correctamente");
                  setTimeout(() => {
                    history.push("/report-list");
                  }, 2000);
                }
            }, 
            error => { 
                error.response?.data ? mostrarError(error.response.data) : mostrarError() 
            });

            setSubmitting(false);    
        }
    });
    const renderAgregarFavoritos = () => {
        return(
            <form onSubmit={formikAgregarFav.handleSubmit} className="form">
                <Modal show={popupAgregarFav} onHide={() => setPopupAgregarFav(false)} centered size="lg">
                    <Modal.Header><Modal.Title>{t("addToFavorite")}</Modal.Title></Modal.Header>
                    <Modal.Body>                            
                        <div className="form-group row">
                            <div className={'col-lg-12'}>
                                <div className="form-group">
                                    <label htmlFor={'name'}>
                                        {t('name')}
                                    </label>
                                    <input
                                        className="form-control"
                                        name='name'
                                        value={formikAgregarFav.values.name}
                                        onChange={(e) => formikAgregarFav.handleChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="m-1 btn btn-sm" disabled={formikAgregarFav.isSubmitting} onClick={() => setPopupAgregarFav(false)}>
                            <i className="fa fa-times" />{" "+t('cancel')}
                        </button>
                        <button type="button" className="m-1 btn btn-sm btn-primary" disabled={formikAgregarFav.isSubmitting} onClick={() => formikAgregarFav.handleSubmit()}>
                            <i className="fa fa-save" />{" "+t('save')}
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
        );
    }

    const formikAgregarLinea = useFormik<any>({
        initialValues: { zone: '', line: '' },
        validationSchema: Yup.object().shape({
            line: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setStatus();
            const linesFilter = filter.lines;
            linesFilter.push({ line_id: values.line });
            setFilter({
                ...filter,
                lines: linesFilter
            });
            setSubmitting(false);
            setPopupAgregarLinea(false);   
        }
    });
    const renderAgregarLinea = () => {
        return(
            <form onSubmit={formikAgregarLinea.handleSubmit} className="form">
                <Modal show={popupAgregarLinea} onHide={() => setPopupAgregarLinea(false)} centered size="lg">
                    <Modal.Header><Modal.Title>{t("addLineToReport")}</Modal.Title></Modal.Header>
                    <Modal.Body>                            
                        <div className="form-group row">
                            <div className={'col-lg-6'}>
                                <div className="form-group">
                                    <label htmlFor={'zone'}>
                                        {t('zone')}
                                    </label>
                                    <select
                                        className="form-select"
                                        name='zone'
                                        value={formikAgregarLinea.values.zone}
                                        onChange={(e) => {
                                            const lines = linesCompleteList.filter(line => parseInt(line.zone_id) === parseInt(e.target.value));
                                            setLinesList(lines);
                                            formikAgregarLinea.handleChange(e);
                                        }}
                                    >
                                        <option>{t('selectOneOption')}</option>
                                        {
                                            zonesList.map(zone => (
                                                <option key={zone.id} value={zone.id}>{zone.descripcion}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className={'col-lg-6'}>
                                <div className="form-group">
                                    <label htmlFor={'line'}>
                                        {t('line')}
                                    </label>
                                    <select
                                        className="form-select"
                                        name='line'
                                        value={formikAgregarLinea.values.line}
                                        onChange={(e) => formikAgregarLinea.handleChange(e)}
                                    >
                                        <option>{t('selectOneOption')}</option>
                                        {
                                            linesList.map(line => (
                                                <option key={line.id} value={line.id}>{line.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="m-1 btn btn-sm" disabled={formikAgregarLinea.isSubmitting} onClick={() => setPopupAgregarLinea(false)}>
                            <i className="fa fa-times" />{" "+t('cancel')}
                        </button>
                        <button type="button" className="m-1 btn btn-sm btn-primary" disabled={formikAgregarLinea.isSubmitting} onClick={() => formikAgregarLinea.handleSubmit()}>
                            <i className="fa fa-save" />{" "+t('save')}
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
        );
    }

    return (
        <div className="card">
            <div className="card-body">
                <h3>{t('generalReportPerLine')}</h3>
                {renderAgregarFavoritos()}
                {renderAgregarLinea()}
                {
                    !report ? 
                    <form>
                        <div className="form-group row">
                            <div className="col-lg-8">
                                <div className="row mt-5">
                                    <div className='col-lg-4' key='start-date'>
                                        <div className='form-group'>
                                            <label htmlFor='start-date'>{t('startDate')}</label>
                                            <input
                                                className='form-control'
                                                type="date"
                                                placeholder='dd/mm/aaaa'
                                                name="startDate"
                                                value={filter.startDate}
                                                onChange={(e) => setFilter({
                                                    ...filter,
                                                    period: '',
                                                    startDate: e.target.value
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4' key='end-date'>
                                        <div className='form-group'>
                                            <label htmlFor='end-date'>{t('endDate')}</label>
                                            <input
                                                className='form-control'
                                                type="date"
                                                placeholder='dd/mm/aaaa'
                                                name="endDate"
                                                value={filter.endDate}
                                                onChange={(e) => setFilter({
                                                    ...filter,
                                                    period: '',
                                                    endDate: e.target.value
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4' key='period'>
                                        <div className='form-group'>
                                            <label htmlFor='end-date'>{t('period')}</label>
                                            <select
                                                className='form-select'
                                                name="period"
                                                value={filter.period}
                                                onChange={(e) => setFilter({
                                                    ...filter,
                                                    period: e.target.value
                                                })}
                                            >
                                                <option value="">{t('selectOneOption')}</option>
                                                {
                                                    getPeriodsList(t).map(period => (
                                                        <option value={period.id} key={period.id}>{period.descripcion}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="row">
                                    <div className="col-lg-12 px-1">
                                        <button className="btn btn-primary btn-sm w-100"
                                            type="button"
                                            onClick={() => setPopupAgregarLinea(true)}>
                                            <i className="fa fa-plus" /> {t('addLineToReport')}
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-lg-8 px-0">
                                        <button className="btn btn-primary btn-sm w-100"
                                            type="button"
                                            disabled={!reportData.length}
                                            onClick={() => setPopupAgregarFav(true)}>
                                            <i className="fa fa-star" /> {t('addToFavorite')}
                                        </button>
                                    </div>
                                    <div className="col-lg-4 px-1">
                                        <button className="btn btn-primary btn-sm w-100"
                                            type="button"
                                            disabled={!reportData.length || loading}
                                            onClick={() => handlePrintPDF()}>
                                                {loading ? (
                                                    <span className='indicator-progress' style={{display: 'inline'}}>
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        {' '}
                                                    </span> 
                                                ) : <i className="fa fa-print" />}
                                                {t('print')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    : 
                    <div className="d-flex flex-row-fluid justify-content-between">
                        <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>
                            <i className="fa fa-arrow-left" /> {t('goBack')}
                        </button>
                        <label className="text-center form-label fw-bold mt-5" style={{ fontSize: '1.3rem' }}>
                            {t('REPORT')}: {report.name.toUpperCase()}
                            {" "}({report.filterDateStart} {"<>"} {report.filterDateEnd})
                        </label>
                        <button className="btn btn-sm btn-primary" onClick={() => handlePrintPDF()}>
                            {loading ? (
                                <span className='indicator-progress' style={{display: 'inline'}}>
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    {' '}
                                </span> 
                            ) : <i className="fa fa-print" />}
                            {t('print')}
                        </button>
                    </div>   
                }
                {
                    !reportData ? <></> : 
                    <div>
                        {
                        reportData.map((group, index) => (
                            <div className="card mt-5 border border-dark" key={index}>
                                <div className="card-header bg-secondary minh-50" style={{ paddingLeft: '2rem', paddingRight: 0 }}>
                                    <div className="d-flex flex-row-fluid justify-content-between">
                                        <div className="card-title my-0">{group.title}</div>
                                        {
                                            !report ? 
                                            <button type="button" className="btn btn-sm"
                                                onClick={() => {
                                                    const linesFilter = filter.lines.filter((l, i) => i !== index);
                                                    setFilter({
                                                        ...filter,
                                                        lines: linesFilter
                                                    })
                                                }}
                                            >
                                                <i className="fa fa-times-circle text-danger" style={{ transform: 'scale(2)' }} /> 
                                            </button>
                                            : <></>
                                        }
                                    </div>
                                </div>
                                <div className="card-body">
                                    <ReportDetail reportData={group}></ReportDetail>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export { ReportGeneralLine };