import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts";

type Props = {
    reportData: any
}

const defaultChartOptions: ApexOptions = {
    chart: {
        type: 'donut',
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        onItemClick: {
            toggleDataSeries: true
        },
        onItemHover: {
            highlightDataSeries: true
        },
        fontSize: '10px'
    },
    responsive: [{
        breakpoint: 480,
        options: {
            legend: {
                show: false
            },
        }
    }]
}

const ReportDetail: FC<Props> = ({ reportData }) => {
    const [dataGroup, setDataGroup] = useState<any>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (reportData) {
            setDataGroup(reportData)
        }
    }, [reportData]);
   
    if(!dataGroup) 
        return null
    else {
        const labelsIssue = dataGroup.detailIssue.map(d => d.condition_abr);
        const colorsIssue = dataGroup.detailIssue.map(d => d.color);
    
        const dataRev = dataGroup.detailRevision.map(d => parseInt(d.count));
        const labelsRev = dataGroup.detailRevision.map(d => d.condition);

        return (
            <div>
                <div className="row">
                    <div className="col-lg-6">
                        <table className="table table-row-bordered table-row-gray-700 table-report-general align-middle text-align-center gs-0 gy-3" style={{ height: '25vh' }}>
                            <thead>
                                <tr className="fw-bolder text-muted bg-light align-items-center">
                                    <th>{t('state')}</th>
                                    <th>{t('structures')}</th>
                                    <th>{t('percent')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !dataGroup.detailRevision ? null :
                                        dataGroup.detailRevision.map(detail => {
                                            return (
                                                <tr key={dataGroup.title + '-' + detail.condition}>
                                                    <td>{t(detail.condition)}</td>
                                                    <td>{detail.count}</td>
                                                    <td>{detail.percent ? detail.percent : '-'}</td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-6">
                                <table className="table table-row-bordered table-report-general table-row-gray-700 align-middle text-align-center gs-0 gy-3" style={{ height: '25vh' }}>
                                    <tbody>
                                        <tr>
                                            <td>{t('numberReviewedStructures')}</td>
                                            <td>{dataGroup.countRevision}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('numberUnreviewedStructures')}</td>
                                            <td>{dataGroup.countNoRevision} ({dataGroup.percentNoRevision.toFixed(2)}%)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-6" style={{ marginBottom: 'auto', marginTop: 'auto' }}>
                                <div className="chart-wrapper">
                                    <Chart type="donut"
                                        series={dataRev}
                                        options={{
                                            ...defaultChartOptions,
                                            labels: labelsRev,
                                            colors: [
                                                '#4dbd74', //normal
                                                '#FFCE56', //low    
                                                '#ff8000', //medium
                                                '#FF6384', //HIGH  
                                            ],
                                        }}
                                    ></Chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <table className="table table-row-bordered table-row-gray-700 table-report-general align-middle text-align-center gs-0 gy-3" style={{ height: '35vh' }}>
                            <thead>
                                <tr className="fw-bolder text-muted bg-light">
                                    <th>{t('type')}</th>
                                    <th>{t('incidents')}</th>
                                    <th>{t('percent')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !dataGroup.detailIssue ? null :
                                        dataGroup.detailIssue.map(detail => {
                                            return (
                                                <tr key={dataGroup.title + '-' + detail.condition}>
                                                    <td>{t(detail.condition)}</td>
                                                    <td>{detail.count}</td>
                                                    <td>{detail.percent ? detail.percent.toFixed(2) : '-'}</td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-6">
                                <table className="table table-row-bordered table-row-gray-700 table-report-general align-middle text-align-center gs-0 gy-3" style={{ height: '35vh' }}>
                                    <tbody>
                                        <tr>
                                            <td>{t('noWOAssignedIncident')}</td>
                                            <td>{dataGroup.issueCount}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('assignedIncidentWithWO')}</td>
                                            <td>{dataGroup.orderCount}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('WOClosed')}</td>
                                            <td>{dataGroup.orderClosedCount}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('backlog')}</td>
                                            <td>{dataGroup.backlogCount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-6" style={{ marginBottom: 'auto', marginTop: 'auto' }}>
                                <div className="chart-wrapper">
                                    <Chart type="donut"
                                        series={dataGroup.detailIssue.map(d => d.count)}
                                        options={{
                                            ...defaultChartOptions,
                                            labels: labelsIssue,
                                            colors: colorsIssue,                                                    
                                        }}
                                    ></Chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );   
    }
}

export { ReportDetail };