import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const USERS = `${REACT_APP_API_URL}/user/`

export function getUsers() {
  return axios.get<{result: any}>(USERS + 'all')
}

export function deleteUser(id) {
  return axios.delete<{result: any}>(USERS + '?id=' + id)
}

export function saveUser(data) {
  return axios.post<{result: any}>(USERS, data)
}

export function resetPassword(username) {
  return axios.get<{result: any}>(USERS + 'reset?username=' + username)
}