import React from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import '../../assets/dashboard.scss';

const TablaIncidenciaTorres = (props) => {

  const { campo, columnas, historial, onIrA, loading } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Breadcrumb>
        {historial.map((item, i) => (
          <BreadcrumbItem key={i} active={i === historial.length - 1}>
            {i === historial.length - 1 ? (
              item
            ) : (
              <button type="button" className="link-button" disabled={loading} onClick={e => onIrA(e, i)}>
                {t(item)}
              </button>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <table className="table dashboard-table table-bordered table-responsive">
        <thead>
          <tr>
            {columnas.map((columna, i) => {
              return (
                <th key={i} className="text-center">
                  {t(columna)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {campo.filas.length > 0 ? (
            campo.filas.map((fila, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Link 
                    //disabled={loading} 
                    to={{ pathname: "/map", state: { tower_id: fila.tower_id } }}>{fila.tower_name}</Link>
                  </td>
                  <td>{fila.issue_number}</td>
                  <td>{fila.rev_number}</td>
                  <td><Moment format={"DD/MM/YYYY"}>{fila.rev_date}</Moment></td>
                  <td>
                    {fila.issue_status === 'REPAIRED' && t('fixed')}
                    {fila.issue_status === 'NOT_REPAIRED' && t('noFixed')}
                    {fila.issue_status === 'SCHEDULED' && t('schduled')}
                  </td>
                  <td>{fila.count}</td>
                  <td style={{ width: '5%' }}>{fila.rev_observations}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td style={{ textAlign: "center" }} colSpan={columnas.length}>
                {t('noData')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default TablaIncidenciaTorres;
