import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import StringFilter from "@inovua/reactdatagrid-community/StringFilter";
import { i18nTable, sanitize } from "../../../../../core/Funciones";
import { getReportsConfig, removeReportConfig, saveReportConfig, getGroups, enviarEmailReport, getReports } from "../../redux/ReportsCRUD";
import { Button, Modal } from "react-bootstrap-v5";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { mostrarError, mostrarExito } from "../../../../../core/EmisorMensajes";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const initialValuesReport = {
    id: 0, report: '', groups: [], periodicity: ''
}
const getPeriodicityName = (periodicity, t) => {
    switch (periodicity) {
        case 'WEEKLY': return t('weekly');
        case 'MONTHLY': return t('monthly');
        case 'FORTNIGHTLY': return t('biweekly');
        case 'ANNUALY': return t('annual');
        case 'UNIQUE': return t('once');
        default: return '';
    }
}
const Report: FC<any> = ({ updateData }) => {
    const [reportsList, setReportsList] = useState<any[]>([]);
    const [reportsConfigList, setReportsConfigList] = useState<any[]>([]);
    const [reportSeleccionado, setReportSeleccionado] = useState<any>(initialValuesReport);
    const [popupEdicionReport, setPopupEdicionReport] = useState(false);
    const [popupEliminarReport, setPopupEliminarReport] = useState(false);
    const [refreshData, setRefreshData] = useState(true);
    const [groupsList, setGroupsList] = useState<any[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        setRefreshData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData])

    useMemo(() => {
        if (refreshData) {
            getReportsConfig().then((res: any) => {
                res.data.forEach(rc => {
                    rc.name = rc.report.name;
                    rc.periodicityName = getPeriodicityName(rc.periodicity, t);
                    rc.groups = rc.groups?.map(gr => gr.group ? gr.group.name : '');
                })
                setReportsConfigList(res.data)
            });

            setRefreshData(false);
        }

        getReports('', true).then((res: any) => {
            const reports = [];
            res.data.forEach(r => {
                reports.push({ id: r.id, descripcion: r.name });
            })
            setReportsList(reports)
        });

        getGroups()
            .then((res: any) => {
                res.data.forEach(r => {
                    r.value = r.id;
                    r.label = `${r.name}`;
                });
                setGroupsList(res.data)
            });
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshData]);

    const validationSchema = Yup.object().shape({
        report: Yup.string().required(t('campoRequerido')),
        periodicity: Yup.string().required(t('campoRequerido')),
        groups: Yup.array().min(1).required(t('campoRequerido')),
    });

    const togglePopupReport = (data) => {
        setTimeout(() => {
            setPopupEdicionReport(!popupEdicionReport);
            if (!data) {
                setReportSeleccionado(initialValuesReport);
            }
            else setReportSeleccionado(sanitize(data));
        });
    }

    const deleteReport = () => {
        if (reportSeleccionado) {
            removeReportConfig(reportSeleccionado.id)
                .then((res: any) => {
                    if (res.status === 200) {
                        mostrarExito(t('changesApplied'));
                        setPopupEliminarReport(false);
                        setRefreshData(true);
                    }
                    else {
                        mostrarError(t(res.data));
                    }
                })
                .catch(err => {
                    if (err?.response?.data) {
                        mostrarError(t(err.response.data));
                    }
                    else {
                        mostrarError(err);
                    }
                });
        }
    }

    const onChangeMultiselect = (value, event, values) => {
        let selection = [];
        if (event.action === "select-option" && event.option.value === "*") {
            selection = [{ label: t('all'), value: "*" }, ...groupsList];
        }
        else if (event.action === "deselect-option" && event.option.value === "*") {
            selection = [];
        }
        else if (value.length === groupsList.length) {
            selection = [{ label: t('all'), value: "*" }, ...groupsList];
        }
        else {
            selection = value;
        }

        setReportSeleccionado({
            ...values,
            groups: selection
        });
    }

    const handleEnviarEmail = (e, config) => {
        enviarEmailReport(config.id)
            .then(res => {
                if (res.status === 200) {
                    mostrarExito(t('emailSent'));
                }
            })
            .catch(err => {
                mostrarError(err);
            });
    }

    return (
        <div>
            <div className="d-flex justify-content-end">
                <Button className="btn btn-success btn-sm" onClick={() => { setPopupEdicionReport(true); }}>
                    <i className="fa fa-plus" /> {t('addConfig')}
                </Button>
            </div>
            <ReactDataGrid
                idProperty="id"
                style={{ minHeight: 600, height: '75vh' }}
                rowHeight={25}
                columns={[
                    { name: 'name', header: t('name'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
                    {
                        name: 'groups', header: t('groupList'), defaultFlex: 1, type: 'string', filterEditor: StringFilter,
                        render: ({ data }) => {
                            return !data || !data.groups ? '' :
                                data.groups.map((r, index) => {
                                    return `${r}${index !== (data.groups.length - 1) ? ', ' : ''}`
                                })
                        }
                    },
                    { name: 'periodicityName', header: t('periodicity'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
                    {
                        name: 'acciones', header: t('actions'), defaultFlex: 1,
                        render: ({ data }) =>
                            <div className="p-0 text-center">
                                {
                                    data.periodicity === 'UNIQUE' ?
                                        <button className="btn btn-sm" onClick={(e) => handleEnviarEmail(e, data)}>
                                            <i className="fa fa-envelope"></i>
                                        </button>
                                        :
                                        <></>
                                }
                                <button className="btn btn-sm" onClick={() => {
                                    setTimeout(() => {
                                        setReportSeleccionado(data);
                                        setPopupEliminarReport(true);   
                                    });
                                }} >
                                    <i className="fa fa-trash text-danger"></i>
                                </button>
                            </div>
                    },
                ]}
                dataSource={reportsConfigList}
                i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                defaultFilterValue={[
                    { name: 'name', operator: 'contains', type: 'string', value: undefined },
                    { name: 'groups', operator: 'contains', type: 'string', value: undefined },
                    { name: 'periodicityName', operator: 'contains', type: 'string', value: undefined },
                ]}
            />

            <React.Fragment>
                <Formik
                    initialValues={reportSeleccionado}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            let reportConfig: any = {
                                report_id: values.report,
                                periodicity: values.periodicity
                            }

                            let groups = [];
                            values.groups.map(
                                g => g.id && groups.push({ groupId: g.id })
                            );
                            reportConfig.groups = groups;

                            saveReportConfig(reportConfig)
                                .then(res => {
                                    if (res.status === 200) {
                                        mostrarExito(t("changesApplied"));
                                        togglePopupReport(null);
                                        setRefreshData(true);
                                    }
                                })
                                .catch(err => {
                                    if (err?.response?.data) {
                                        mostrarError(err.response.data);
                                    }
                                    else {
                                        mostrarError(err);
                                    }
                                });
                            setSubmitting(false);
                        }, 400);
                    }}>
                    {({ values, errors, isSubmitting, handleSubmit, handleReset }) => (
                        <>
                            <Modal
                                show={popupEdicionReport}
                                onHide={() => togglePopupReport(null)}
                                centered
                                size="lg">
                                <Modal.Header><Modal.Title>{t("addConfig")}</Modal.Title></Modal.Header>
                                <Modal.Body>
                                    <div className="form-report row">
                                        <div className='col-lg-6' key='report'>
                                            <div className='form-report'>
                                                <label htmlFor='name'>{t('report')}</label>
                                                <Field className='form-select' as="select" name="report">
                                                    <option>{t('selectOneOption')}</option>
                                                    {
                                                        reportsList.map(report => (
                                                            <option key={report.id} value={report.id}>{report.descripcion}</option>
                                                        ))
                                                    }
                                                </Field>
                                                <ErrorMessage name="report" component="div" className="formik-error-message" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6' key='periodicity'>
                                            <div className='form-report'>
                                                <label htmlFor='periodicity'>{t('periodicity')}</label>
                                                <Field className='form-select' as="select" name="periodicity">
                                                    <option>{t('selectOneOption')}</option>
                                                    <option value="WEEKLY">{t('weekly')}</option>
                                                    <option value="FORTNIGHTLY">{t('biweekly')}</option>
                                                    <option value="MONTHLY">{t('monthly')}</option>
                                                    <option value="ANNUALY">{t('annual')}</option>
                                                    <option value="UNIQUE">{t('once')}</option>
                                                </Field>
                                                <ErrorMessage name="periodicity" component="div" className="formik-error-message" />
                                            </div>
                                        </div>
                                        <div className='col-lg-12' key='groups'>
                                            <div className='form-report'>
                                                <label htmlFor='groups'>{t('group')}</label>
                                                <ReactMultiSelectCheckboxes
                                                    className='form-control multiselect-checkbox'
                                                    name="groups"
                                                    isClearable={true}
                                                    value={reportSeleccionado.groups}
                                                    multiple={true}
                                                    width={'100%'}
                                                    placeholder={t("enterAValue...")}
                                                    placeholderButtonLabel={t('selectOneOption')}
                                                    getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                                                        return value && value.length > 0 ? `${value.length} ítems seleccionados` : placeholderButtonLabel;
                                                    }}
                                                    onChange={(value, event) => onChangeMultiselect(value, event, values)}
                                                    options={[{ label: t('all'), value: "*" }, ...groupsList]}
                                                >
                                                </ReactMultiSelectCheckboxes>
                                                {errors.groups && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block formik-error-message'>{errors.groups}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                                        handleReset();
                                        togglePopupReport(null);
                                    }}>
                                        <i className="fa fa-times" />{" " + t('cancel')}
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-sm m-1" disabled={isSubmitting}
                                        onClick={() => handleSubmit()}>
                                        <i className="fa fa-save" />{" " + t('save')}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={popupEliminarReport}
                                onHide={() => setPopupEliminarReport(false)}
                                centered
                                size="lg">
                                <Modal.Header><Modal.Title>Confirmación</Modal.Title></Modal.Header>
                                <Modal.Body>
                                    <label>{t('confirmDeleteRecipient')}</label>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="m-1 btn btn-sm" onClick={() => setPopupEliminarReport(false)}>
                                        <i className="fa fa-times" />{" " + t('cancel')}
                                    </button>
                                    <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => deleteReport()}>
                                        <i className="fa fa-save" />{" " + t('confirm')}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
                </Formik>
            </React.Fragment>
        </div>
    );
}

export { Report };