import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const GET_CONFIG = `${REACT_APP_API_URL}/map/config`
export const TOWER = `${REACT_APP_API_URL}/tower/`
export const LINE = `${REACT_APP_API_URL}/line/`
export const KPI = `${REACT_APP_API_URL}/kpi/`

export function getConfig() {
  return axios.get<{result: any}>(GET_CONFIG)
}

export function saveConfigMap(data) {
  return axios.post<{result: any}>(GET_CONFIG, data)
}

export function getAllTowers() {
  return axios.get<{result: any}>(TOWER + 'all')
}

export function getZones() {
  return axios.get<{result: any}>(LINE + 'zones');
}

export function getLines() {
  return axios.get<{result: any}>(LINE + 'all');
}

export function getResumenKPIMap(filter) {
  return axios.post<{result: any}>(KPI + 'resumen-kpi-map', filter);
}

export function getLinePaths(linesIds, voltagesList) {
  return axios.get<{result: any}>(LINE + `path-list?ids=${linesIds}&voltageLevel=${voltagesList}`)
}

export function getStructure(id) {
  return axios.get<{result: any}>(TOWER + 'get/' + id)
}

export function getLine(id) {
  return axios.get<{result: any}>(LINE + 'get/' + id);
}

export function searchData(pattern) {
  return axios.get<{result: any}>(LINE + 'find/pattern?pattern=' + pattern);
}