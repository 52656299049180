import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap-v5";
import { Receiver } from "./report-config/Receiver";
import { Group } from "./report-config/Group";
import { Report } from "./report-config/Report";

const ReportConfig: FC = () => {
    const [updateData, setUpdateData] = useState(false);

    const { t } = useTranslation();

    const handleUpdateData = (value) => {
        setUpdateData(value);
    }

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="mb-5">{t('configReports')}</h3>
                <Tabs defaultActiveKey="receiver">
                    <Tab eventKey="receiver" title={t('recipients')}>
                        <Receiver handleUpdateData={(value) => handleUpdateData(value)}></Receiver>
                    </Tab>
                    <Tab eventKey="group" title={t('groups')}>
                        <Group updateData={updateData} handleUpdateData={(value) => handleUpdateData(value)}></Group>
                    </Tab>
                    <Tab eventKey="report" title={t('scheduledReports')}>
                        <Report updateData={updateData}></Report>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export { ReportConfig };