import axios from 'axios';
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TablaIncidenciasDetalleLinea from './tablas/TablaIncidenciasDetalleLinea';
import TablaIncidenciasDetalleZona from './tablas/TablaIncidenciasDetalleZona';
import TablaIncidenciasLinea from './tablas/TablaIncidenciasLinea';
import TablaIncidenciasZona from './tablas/TablaIncidenciasZona';
import TablaIncidenciaTorres from './tablas/TablaIncidenciaTorres';
const { REACT_APP_API_URL } = process.env

const Table: FC<any> = ({ filtro }) => {

  const [filter, setFilter] = useState(filtro);
  const [historialModo, setHistorialModo] = useState([]);
  const [campos, setCampos] = useState({
    listaIncidenciaZona: {
      label: "Incidencias por zona",
      tipo: "tabla",
      valido: null,
      obligatorio: true,
      feedback: "",
      columnas: [],
      filas: []
    },
    listaIncidenciasLinea: {
      label: "Incidencias por línea",
      tipo: "tabla",
      valido: null,
      obligatorio: true,
      feedback: "",
      columnas: [],
      filas: []
    },
    listaIncidenciasDetalleZona: {
      label: "Incidencias por zona",
      tipo: "tabla",
      valido: null,
      obligatorio: true,
      feedback: "",
      columnas: [],
      filas: []
    },
    listaIncidenciasDetalleLinea: {
      label: "Incidencias por línea",
      tipo: "tabla",
      valido: null,
      obligatorio: true,
      feedback: "",
      columnas: [],
      filas: []
    },
    listaIncidenciaTorre: {
      label: "Detalles de la incidencia en torres",
      tipo: "tabla",
      valido: null,
      obligatorio: true,
      feedback: "",
      columnas: [],
      filas: []
    }
  });
  const [historialMenus, setHistorialMenus] = useState(["Incidencias por zona"]);
  const [loading, setLoading] = useState(false);
  const [filaSeleccionada, setFilaSeleccionada] = useState(null);
  const [columnaSeleccionada, setColumnaSeleccionada] = useState(null);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setFilter(filtro);

    setLoading(true);
    axios
    .post(`${REACT_APP_API_URL}/dashboard/zone-category-issue`, { date_start: filtro.date_start, date_end: filtro.date_end })
    .then(res => {
      setCampos({
        ...campos,
        listaIncidenciaZona: {
          ...campos.listaIncidenciaZona,
          filas: res.data.data ? res.data.data : [],
          columnas: res.data.columns
        }
      })

      setHistorialModo(["FZONAS_CCATEGORIAS"])
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro])

  useEffect(() => {
    generarTablas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historialModo])

  /**
   * Si hace click en una zona, debe mostrar todas las lineas en forma de filas
   * y debe haber una columna por cada categoria de incidencia.
   */
  const handleClickZona = (e, fila) => {
    e.preventDefault();
    setLoading(true);

    setFilaSeleccionada(fila)
    axios
      .post(`${REACT_APP_API_URL}/dashboard/line-category-issue`, { zone: fila.firstColumn, date_start: filter.date_start, date_end: filter.date_end })
      .then(res => {

        setCampos({
          ...campos,
          listaIncidenciasLinea: {
            ...campos.listaIncidenciasLinea,
            filas: res.data.data ? res.data.data : [],
            columnas: res.data.columns
          }
        })

        setHistorialModo([
          ...historialModo,
          "FLINEAS_CCATEGORIAS"
        ])
        const menu = `${t("Incidencias por zona")}: ${t(fila.firstColumn)}`;
        if (historialMenus.indexOf(menu) === -1) {
          setHistorialMenus([
            ...historialMenus,
            menu
          ])
        }
        setLoading(false);
      });

    setFilter({
      ...filter,
      zona: fila.firstColumn,
      category_name: null
    })
  };

  /**
   * Si hace click en una de las columnas de la tabla, debe mostrar una columna por cada
   * codigo de incidencia y una fila por cada zona
   */
  const handleClickTipoIncidencia = (e, columna) => {
    e.preventDefault();
    setLoading(true);

    setColumnaSeleccionada(columna.replace('\\n', ''))
    axios
      .post(`${REACT_APP_API_URL}/dashboard/zone-code-issue`, { category_name: columna, date_start: filter.date_start, date_end: filter.date_end })
      .then(res => {

        setCampos({
          ...campos,
          listaIncidenciasDetalleZona: {
            ...campos.listaIncidenciasDetalleZona,
            filas: res.data.data ? res.data.data : [],
            columnas: res.data.columns
          }
        })

        setHistorialModo([
          ...historialModo,
          "FZONAS_CCODIGOS"
        ])
        const menu = `${t("Incidencias por tipo de incidencia")}: ${t(columna)}`;
        if (historialMenus.indexOf(menu) === -1) {
          setHistorialMenus([
            ...historialMenus,
            menu
          ])
        }
        setLoading(false);
      });

    setFilter({
      ...filter,
      category_name: columna,
      zona: null
    })
  };

  /**
   * Debe mostrar una columna por cada codigo de incidencia y una fila por cada línea
   */
  const handleClickTipoIncidenciaLinea = (e, columna) => {
    e.preventDefault();
    setLoading(true);

    setColumnaSeleccionada(columna.replace('\\n', ''));
    axios
      .post(`${REACT_APP_API_URL}/dashboard/line-code-issue`, {
        category_name: columna, zone: filaSeleccionada.firstColumn,
        date_start: filter.date_start, date_end: filter.date_end
      })
      .then(res => {

        setCampos({
          ...campos,
          listaIncidenciasDetalleLinea: {
            ...campos.listaIncidenciasDetalleLinea,
            filas: res.data.data ? res.data.data : [],
            columnas: res.data.columns
          }
        })

        setHistorialModo([
          ...historialModo,
          "FLINEAS_CCODIGOS"
        ])
        const menu = `${t("Incidencias por tipo de incidencia")}: ${t(columna)}`;
        if (historialMenus.indexOf(menu) === -1) {
          setHistorialMenus([
            ...historialMenus,
            menu
          ])
        }
        setLoading(false);
      });

    setFilter({
      ...filter,
      zona: filaSeleccionada.firstColumn,
      category_name: columna
    })
  };

  /**
   * Debe mostrar una columna por cada codigo de incidencia y una fila por cada línea
   */
  const handleClickTipoIncidenciaZona = (e, fila) => {
    e.preventDefault();
    setLoading(true);

    setFilaSeleccionada(fila)
    axios
      .post(`${REACT_APP_API_URL}/dashboard/line-code-issue`, {
        category_name: columnaSeleccionada, zone: fila.firstColumn,
        date_start: filter.date_start, date_end: filter.date_end
      })
      .then(res => {

        setCampos({
          ...campos,
          listaIncidenciasDetalleLinea: {
            ...campos.listaIncidenciasDetalleLinea,
            filas: res.data.data ? res.data.data : [],
            columnas: res.data.columns
          }
        })

        setHistorialModo([
          ...historialModo,
          "FLINEAS_CCODIGOS"
        ])
        const menu = `${t("Incidencias por zona")}: ${t(fila.firstColumn)}`;
        if (historialMenus.indexOf(menu) === -1) {
          setHistorialMenus([
            ...historialMenus,
            menu
          ])
        }
        setLoading(false);
      });
    setFilter({
      ...filter,
      zona: fila.firstColumn,
      category_name: columnaSeleccionada
    })
  };

  /**
   * Cuando se hace click sobre una linea y una incidencia, se deben mostrar todas las torres de esa linea
   * que contengan dicha incidencia sin resolver.
   */
  const handleClickIncidenciaLinea = (e, fila, columna) => {
    e.preventDefault();
    setLoading(true);

    //filaSeleccionada = fila;
    columna = columna.replace('\\n', '');
    axios
      .post(`${REACT_APP_API_URL}/dashboard/issues-details`, {
        issue_code: columna, line: fila.firstColumn,
        date_start: filter.date_start, date_end: filter.date_end
      })
      .then(res => {

        setCampos({
          ...campos,
          listaIncidenciaTorre: {
            ...campos.listaIncidenciaTorre,
            filas: res.data.data ? res.data.data : [],
            columnas: res.data.columns
          }
        })

        setHistorialModo([
          ...historialModo,
          "FTORRES_CESTADO"
        ])
        const menu = `${t(columna)} - ${t(fila.firstColumn)}`;
        if (historialMenus.indexOf(menu) === -1) {
          setHistorialMenus([
            ...historialMenus,
            menu
          ])
        }
        setLoading(false);
      });
  };

  const handleIrA = (e, indice) => {
    if (e !== null) {
      e.preventDefault();
    }

    const historialM = historialModo.slice(0, indice + 1);
    setHistorialModo(historialM);

    const historialMe = historialMenus.slice(0, indice + 1)
    setHistorialMenus(historialMe);

    const ultimo = historialModo[historialModo.length - 1];
    switch (ultimo) {
      case 'FLINEAS_CCATEGORIAS': {
        setColumnaSeleccionada('');
        break;
      }
      case 'FZONAS_CCATEGORIAS': {
        setFilaSeleccionada(null);
        setColumnaSeleccionada(null);
        break;
      }
      case 'FZONAS_CCODIGOS': {
        setFilaSeleccionada(null);
        break;
      }
    }

    setFilter({
      ...filter,
      zone: filaSeleccionada?.firstColumn,
      category_name: columnaSeleccionada
    })
  };

  const handleIrAMapa = (towerId) => {
    history.push("/map?towerId=" + towerId)
  }

  const volver = () => {
    if (historialModo.length > 1) {
      handleIrA(null, historialModo.length - 2); //ultimo elemento -1
    }
    else {
      history.goBack();
    }
  };

  const generarTablas = () => {
    switch (historialModo[historialModo.length - 1]) {
      case "FZONAS_CCATEGORIAS":
        return (
          <TablaIncidenciasZona
            campo={campos.listaIncidenciaZona}
            historial={historialMenus}
            columnas={campos.listaIncidenciaZona.columnas}
            onClickZona={handleClickZona}
            onClickTipoIncidencia={handleClickTipoIncidencia}
            loading={loading}
          />
        );
      case "FLINEAS_CCATEGORIAS":
        return (
          <TablaIncidenciasLinea
            campo={campos.listaIncidenciasLinea}
            historial={historialMenus}
            columnas={campos.listaIncidenciasLinea.columnas}
            onIrA={handleIrA}
            onClickTipoIncidencia={handleClickTipoIncidenciaLinea}
            loading={loading}
          />
        );
      case "FZONAS_CCODIGOS":
        return (
          <TablaIncidenciasDetalleZona
            campo={campos.listaIncidenciasDetalleZona}
            historial={historialMenus}
            columnas={campos.listaIncidenciasDetalleZona.columnas}
            onClickZona={handleClickTipoIncidenciaZona}
            onIrA={handleIrA}
            loading={loading}
          />
        );
      case "FLINEAS_CCODIGOS":
        return (
          <TablaIncidenciasDetalleLinea
            campo={campos.listaIncidenciasDetalleLinea}
            historial={historialMenus}
            columnas={campos.listaIncidenciasDetalleLinea.columnas}
            onClickIncidenciaLinea={handleClickIncidenciaLinea}
            onIrA={handleIrA}
            loading={loading}
          />
        );
      case "FTORRES_CESTADO":
        return (
          <TablaIncidenciaTorres
            campo={campos.listaIncidenciaTorre}
            historial={historialMenus}
            columnas={campos.listaIncidenciaTorre.columnas}
            onIrA={handleIrA}
            onIrAMapa={handleIrAMapa}
            loading={loading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='d-flex flex-row'>
      <div className='card px-3'>
        <div className='card-body p-1 mt-5'>
          {
            generarTablas()
          }
        </div>
        <div className='card-footer p-1'>
          {historialModo.length === 1 && historialModo[0] === 'FZONAS_CCATEGORIAS' ? null :
            <button className='m-1 btn btn-sm btn-secondary'
              type="button"
              onClick={() => volver()}
            >
              <i className="fa fa-arrow-left" /> {t('goBack')}
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export { Table }
