import {FC} from 'react'

const Error401: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Acceso no autorizado</h1>
    </>
  )
}

export {Error401}
