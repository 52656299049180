export enum ROLE {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_OPERATOR = 'ROLE_OPERATOR',
    ROLE_OBSERVATOR = 'ROLE_OBSERVATOR'
}

export const getRoles = (role) => {
    switch(role) {
        case ROLE.ROLE_ADMIN : 
            return [ROLE.ROLE_ADMIN, ROLE.ROLE_OPERATOR, ROLE.ROLE_OBSERVATOR];
        case ROLE.ROLE_OPERATOR : 
            return [ROLE.ROLE_OPERATOR, ROLE.ROLE_OBSERVATOR];
        case ROLE.ROLE_OBSERVATOR : 
            return [ROLE.ROLE_OBSERVATOR];
        default:
            return [];
    }
}

export const authorizationStrategy = (currentRoles, requirement) => {
    return currentRoles ? currentRoles.find(role => role === requirement) : null;
};
  
export const loginAuthorizationStrategy = (currentRoles, requirement) => {
    return currentRoles && currentRoles.length;
};

export type RouteProps = {
    component: any,
    path: string,
    role?: ROLE
}