import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import { getRevisions, getRevisionsPlans } from "../../../reports/redux/ReportsCRUD";
import * as Yup from "yup";
import { getLinesWithRevision } from "../../redux/UploadsCRUD";

type Props = {
    open: boolean;
    onClose: () => void;
    onConfirm: (data, line, revisionPlanId) => void;
}
const ModalEditRevision = (props: Props) => {
    const { open, onClose, onConfirm } = props;

    const [openModal, setOpenModal] = useState(open);
    const [lines, setLines] = useState([]);
    
    const [revisionsPlans, setRevisionsPlans] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
      setOpenModal(open);
    }, [open]);

    useEffect(() => {
        getLinesWithRevision().then((res: any) => setLines(res.data));
    }, []);

    const handleSelectLine = (lineSelected) => {
        if (lineSelected) {
            getRevisionsPlans(lineSelected)
            .then((res: any) => {
                setRevisionsPlans(res.data);
            });
        }
    };

    const RevSchema = Yup.object().shape({
        lineId: Yup.number().optional(),
        revisionPlanId: Yup.number().required(t('campoRequerido')),
    });

    return(
        <Formik
            initialValues={{
                revisionPlanId: '',
                lineId: '',
            }}
            validationSchema={RevSchema}
            onSubmit={async (values, { setSubmitting }) => {
                if(values.revisionPlanId) {
                    const response = await getRevisions(values.revisionPlanId);
                    const line = lines.filter(l => l.id === values.lineId)[0];
                    if(response) {
                        onConfirm(response.data, line, values.revisionPlanId);
                    }
                }
                setSubmitting(false);
            }}
        >
            {({ values, isSubmitting, handleChange, handleSubmit, isValid }) => (
                <Modal show={openModal} className={"modal-info"} size="lg" centered onHide={() => onClose()} >
                    <Modal.Header>
                        <Modal.Title>Seleccionar revisión</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column flex-wrap">
                            <div className="form-group">
                                <label htmlFor={'line'}>
                                    {t('line')}
                                </label>
                                <select
                                    className="form-select"
                                    name='lineId'
                                    value={values.lineId}
                                    onChange={e => {
                                        handleChange(e);
                                        handleSelectLine(e.target.value);
                                    }}
                                >
                                    <option>{t('selectOneOption')}</option>
                                    {
                                        lines.map(line => (
                                            <option key={line.id} value={line.id}>{line.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={'revision'}>
                                    {t('revision')}
                                </label>
                                <select
                                    className="form-select"
                                    name='revisionPlanId'
                                    value={values.revisionPlanId}
                                    onChange={handleChange}
                                >
                                    <option>{t('selectOneOption')}</option>
                                    {
                                        revisionsPlans.map(rev => (
                                            <option key={rev.revision_plan_id} value={rev.revision_plan_id}>{`${rev.rev_number} - ${rev.rev_date}`}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary btn-sm"  onClick={() => onClose()}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary btn-sm" onClick={() => handleSubmit()} disabled={!isValid}>
                            Confirmar
                        </button>{" "}
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    )
}
export default ModalEditRevision;