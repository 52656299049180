import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mostrarError, mostrarExito } from "../../../../core/EmisorMensajes";
import { getConfig, saveConfigMap } from "../../../../app/modules/map/redux/MapCRUD";
import { Modal } from "react-bootstrap-v5";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { MASCARAS } from "../../../../core/FuncionesCampos";

const MapConfig: FC<any> = ({openModal, setOpenModal}) => {
    const [config, setConfig] = useState(null);
    const [open, setOpen] = useState(null);

    const { t } = useTranslation();
    const campos = {
        latitude: {
            label: "latitude",
            placeholder: "99.999999",
            tipo: "text",
            ancho: 6,
            obligatorio: true
        },
        longitude: {
            label: "longitude",
            placeholder: "99.999999",
            tipo: "text",
            ancho: 6,
            obligatorio: true
        },
        zoom: {
            label: "zoomLevel",
            placeholder: "99",
            tipo: "number",
            ancho: 6,
            min: 1,
            max: 20,
            obligatorio: true
        }
    }

    const ConfigSchema = Yup.object().shape({
        latitude: Yup.string().required(t('campoRequerido')).matches(MASCARAS.coordenada, t('soloCoordenada')),
        longitude: Yup.string().required(t('campoRequerido')).matches(MASCARAS.coordenada, t('soloCoordenada')),
        zoom: Yup.number().required(t('campoRequerido')),
    });

    useEffect(() => {
        setOpen(openModal);
    }, [openModal])

    useEffect(() => {
        getConfig()
        .then(res => {
            if (res.data) {
                setConfig(res.data);
            }
        })
        .catch(err => {
            mostrarError(err);
        });
    }, [])

    const saveConfig = (values) => {
        let mapConfig = {
            id: Number.parseInt(values.idConfig),
            latitude: values.latitude,
            longitude: values.longitude,
            zoom: Number.parseInt(values.zoom),
        }

        saveConfigMap(mapConfig)
        .then(res => {
            if (res.data) {
                mostrarExito("La configuración se ha guardado correctamente");
                setOpen(false);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        })
        .catch(err => {
            mostrarError(err);
        });
    }

    if(!config) return null;
    else 
    return (
        <Formik
            initialValues={config}
            validationSchema={ConfigSchema}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    saveConfig(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({ values, isSubmitting, handleChange, handleSubmit }) => (
                <Modal show={open} className={"modal-info"} size="lg" centered onHide={() => {
                    setOpen(false);
                    setOpenModal(false)
                }} >
                    <Modal.Header>
                        <Modal.Title>{t('customizeMap')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form action="" method="post">
                            <div className="form-group row">
                                {Object.keys(campos).map(indice => {
                                    return (
                                        <div key={indice} className={"col-lg-" + campos[indice].ancho}>
                                            <div className="form-group">
                                                <label htmlFor={indice}>
                                                    {t(campos[indice].label)} {campos[indice].obligatorio ? "*" : ""}
                                                </label>
                                                <Field
                                                    name={indice}
                                                    className='form-control'
                                                    type={campos[indice].tipo}
                                                    value={values[indice]}
                                                    onChange={handleChange}
                                                    readOnly={campos[indice].soloLectura}
                                                    placeholder={campos[indice].placeholder}
                                                />
                                                <ErrorMessage name={indice} component="div" className="form-error-message" />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary btn-sm"  onClick={() => {
                            setOpen(false);
                            setOpenModal(false)
                        }}>
                            {t("cancel")}
                        </button>
                        <button className="btn btn-primary btn-sm" onClick={() => handleSubmit()} disabled={isSubmitting}>
                            {t("confirm")}
                        </button>{" "}
                    </Modal.Footer>
                </Modal>
            )}
            </Formik>
    );
}

export default MapConfig;