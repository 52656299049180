import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getIssueDetails, getLines, getRevisionsPlans, printReportIssues } from "../redux/ReportsCRUD";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { exportar, i18nTable } from "../../../../core/Funciones";
import { mostrarInfo } from "../../../../core/EmisorMensajes";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

const defaultDropdownButtonStyle = {
    padding: '8px',
    backgroundColor: '#FFF',
    borderRadius: '2px',
    borderWidth: 0,
    //boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.08)',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.36,
    alignItems: 'baseline',
    background: '#FFF',
    boxSizing: 'border-box',
    borderColor: '#eee',
    color: '#515151',
    cursor: 'pointer',
    display: 'inline-flex',
    margin: 0,
    maxWidth: '100%',
    flexWrap: 'nowrap',
    outline: 'currentcolor none medium !important',
    textAlign: 'left',
    textDecoration: 'none',
    transition: 'background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: 'auto',
    ':hover': {
      background: 'white'
    }
};
const initialValues = {
    startDate: '', endDate: '', line_id: '', revisions: []
}
const ReportIssuesLine: FC = () => {
    const [linesList, setLinesList] = useState<any[]>([]);
    const [revisionsList, setRevisionsList] = useState<any[]>([]);
    const [filter, setFilter] = useState<any>(initialValues);
    const [issuesList, setIssuesList] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const _export = React.useRef(null);

    useEffect(() => {
        getLines().then((res: any) => setLinesList(res.data));
        searchRevisionPlans('');
    }, []);

    useEffect(() => {
        if(filter.line_id) {
            searchRevisionPlans(filter.line_id);
        }
    }, [filter.line_id]);

    const searchRevisionPlans = lineId => {
        getRevisionsPlans(lineId).then((res: any) => {
            res.data.forEach((r, index) => {
                r.label = `N° ${r.number} - ${r.date}`;
                r.value = index;
            });
            setRevisionsList(res.data);
        });
    }

    const search = () => {
        if(filter.line_id) {
            let revisiones = [];
            if(filter.revisions && Array.isArray(filter.revisions)) {
                revisiones = filter.revisions.filter(r => r.date && r.number);
            }

            const periodValid = ((!filter.startDate && !filter.endDate) || (filter.startDate && filter.endDate && filter.startDate < filter.endDate));
            if(!periodValid) {
                mostrarInfo(t('errorPeriodInvalid'));
                return;
            }

            let filterData = {
                revisions: revisiones,
                line_id: filter.line_id ? Number.parseInt(filter.line_id) : '',
                startDate: filter.startDate,
                endDate: filter.endDate
            }

            if(!filter.revisions && !filter.line_id) {
                mostrarInfo(t('mustSelectLineAndRevision'));
            }
            else{
                setLoading(true);
                getIssueDetails(filterData)
                .then((res: any) => {
                    if (res && res.data) {
                        res.data.forEach(fila => {
                            fila.issue_status = t(fila.issue_status);
                        });

                        setIssuesList(res.data);
                        setLoading(false);
                    }
                });
            }
        }
    }

    const printPDF = () => {
        let revisiones = [];
        if(filter.revisions && Array.isArray(filter.revisions)) {
            revisiones = filter.revisions.filter(r => r.date && r.number);
        }

        const periodValid = ((!filter.startDate && !filter.endDate) || (filter.startDate && filter.endDate && filter.startDate < filter.endDate));
        if(!periodValid) {
            mostrarInfo(t('errorPeriodInvalid'));
            return;
        }
        
        const filterData = {
            startDate: filter.startDate,
            endDate: filter.endDate,
            revisions: revisiones,
            line_id: filter.line_id ? filter.line : null
        }

        if(!filter.revisions && !periodValid) {
            mostrarInfo(t("mustSelectValidPeriod"));
        }
        else{
            setLoading(true);
            printReportIssues(filterData)
            .then(res => {
                if(res && res.data){
                    exportar(res.data, `reporte_incidencias`);
                    setLoading(false);
                }
            });
        }
    }

    const exportarExcel = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (_export.current !== null) {
            _export.current.save();
        }
    }

    const onChangeMultiselect = (value, event) => {
        let revsSelection = [];
        if (event.action === "select-option" && event.option.value === "*") {
            revsSelection = [{ label: t('all'), value: "*" }, ...revisionsList];
        } 
        else if (event.action === "deselect-option" && event.option.value === "*") {
            revsSelection = [];
        } 
        else if (value.length === revisionsList.length) {
            revsSelection = [{ label: t('all'), value: "*" }, ...revisionsList];
        } 
        else {
            revsSelection = value;
        }

        setFilter({
            ...filter,
            startDate: '', 
            endDate: '',
            revisions: revsSelection
        });
    }

    const customStyles = {
        dropdownButton: () => ({
            ...defaultDropdownButtonStyle,
            border: 'solid 1px #eee',
            borderRadius: '5px',
            width: '100%',
        }),
    }

    return (
        <div className="card">
            <div className="card-body">
                <h3>{t('incidentsPerLine')}</h3>
                <form>
                    <div className="form-group row">
                        <div className='col-lg-3' key='line'>
                            <div className='form-group'>
                                <label htmlFor='line'>{t('line')}</label>
                                <select
                                    className='form-select'
                                    name="line_id"
                                    value={filter.line_id}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        line_id: e.target.value
                                    })}
                                >
                                    <option value="">{t('selectOneOption')}</option>
                                    {
                                        linesList.map(line => (
                                            <option value={line.id} key={line.id}>{line.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3' key='revision'>
                            <div className='form-group'>
                                <label htmlFor='line'>{t('revision')}</label>
                                <ReactMultiSelectCheckboxes
                                    className='form-control multiselect-checkbox'
                                    styles={customStyles}
                                    name="revisions"
                                    isClearable={true}
                                    value={filter.revisions}
                                    multiple={true}
                                    width={'100%'}
                                    placeholder={t("enterAValue...")}
                                    placeholderButtonLabel={t('selectOneOption')}
                                    getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                                        return value && value.length > 0 ? `${value.length} ítems seleccionados` : placeholderButtonLabel;
                                    }}
                                    onChange={(value, event) => onChangeMultiselect(value, event)}
                                    options={[{ label: t('all'), value: "*" }, ...revisionsList]}
                                >
                                </ReactMultiSelectCheckboxes>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='form-group'>
                                <label htmlFor='start-date'>{t('startDate')}</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    placeholder='dd/mm/aaaa'
                                    name="startDate"
                                    value={filter.startDate}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        revisions: [],
                                        startDate: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-lg-2' key='end-date'>
                            <div className='form-group'>
                                <label htmlFor='end-date'>{t('endDate')}</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    placeholder='dd/mm/aaaa'
                                    name="endDate"
                                    value={filter.endDate}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        revisions: [],
                                        endDate: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        
                        <div className='col-lg-2'>
                            <button className="btn btn-primary btn-sm mt-7" type="button" onClick={() => search()} disabled={loading}>
                                <i className="fa fa-search" /> {t('search')}
                            </button>
                            <button className="btn btn-secondary btn-sm mt-7" type="button" onClick={() => printPDF()} disabled={loading}>
                                <i className="fa fa-print" /> 
                            </button>
                            <button className="btn btn-success btn-sm mt-7" onClick={(event) => exportarExcel(event)}>
                                <i className="fa fa-download"></i>
                            </button>
                        </div>
                    </div>
                </form>
                {
                    !filter.revisions.lenght || (filter.startDate && filter.endDate) ? 
                    <div className="mt-10">
                        {
                            <ReactDataGrid
                                idProperty="id"
                                style={{ minHeight: 600, height: '72vh' }}
                                rowHeight={25}
                                columns={[
                                    { name: 'line_name', header: t('line'), defaultFlex: 1, type: 'string' },
                                    { name: 'tower_name', header: t('structure'), defaultFlex: 1, type: 'string'},
                                    { name: 'issue_code_name', header: t('incidentCode'), defaultFlex: 1, type: 'string'},
                                    { name: 'issue_number', header: t('incidentNumber'), defaultFlex: 1, type: 'number'},
                                    { name: 'ot_number', header: t('wo'), defaultFlex: 1, type: 'number'},
                                    { name: 'schedule_date', header: t('dateWO'), defaultFlex: 1, type: 'date'},
                                    { name: 'issue_status', header: t('state'), defaultFlex: 1, type: 'string'},
                                    { name: 'end_date', header: t('endDateWO'), defaultFlex: 1, type: 'date'},
                                ]}
                                dataSource={issuesList}
                                i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                            />
                        }
                        {
                            <ExcelExport
                                data={issuesList}
                                fileName="Detalle_Incidencias.xlsx"
                                ref={_export}>
                                <ExcelExportColumn field={"line_name"} title={t('line')}/>
                                <ExcelExportColumn field={"tower_name"} title={t('structure')} />
                                <ExcelExportColumn field={"issue_code_name"} title={t('incidentCode')} />
                                <ExcelExportColumn field={"issue_number"} title={t('incidentNumber')} />
                                <ExcelExportColumn field={"ot_number"} title={t('numberWO')} />
                                <ExcelExportColumn field={"schedule_date"} title={t('startDateSchedule')} />
                                <ExcelExportColumn field={"issue_status"} title={t('state')} />
                                <ExcelExportColumn field={"end_date"} title={t('endDate')} />
                            </ExcelExport>
                        }
                    </div>
                    :
                    <div className="card">
                        <div className="card-header  bg-secondary">
                            <div className="card-title mx-auto">{t('mustSelectLineAndRevision')}</div>
                        </div>    
                    </div>
                }
            </div>
        </div>
    );
}

export { ReportIssuesLine };