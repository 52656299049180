export const imprimir = (data) => {
    const file = new Blob([data], { type: 'application/pdf' });

    let readerC = new FileReader();
    readerC.readAsDataURL(file);
    readerC.onloadend = function () {
        var base64data = readerC.result;

        var winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
            'resizable,screenX=50,screenY=50,width=850,height=1050';
        var htmlPop = '<embed width=100% height=100%'
            + ' type="application/pdf" src="' + base64data + '"></embed>';
        var printWindow = window.open("", "PDF", winparams);
        printWindow.document.write(htmlPop);
        //printWindow.print();
    }
};

export const exportar = (data, nombre) => {
    const file = new Blob(
        [data], 
        {type: 'application/pdf'});

      let readerC = new FileReader();
      readerC.readAsDataURL(file); 
      readerC.onloadend = function() {
        var base64data = readerC.result;                
        const downloadLink:any = document.createElement("a");
        const fileName = `${nombre}.pdf`;

        downloadLink.href = base64data;
        downloadLink.download = fileName;
        downloadLink.click();
      }
}

export const ordenarPorPropiedad = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export const i18nTable = (t) => {
    const CLAVES = [
        'pageText', 'ofText', 'perPageText', 'showingText', 'clearAll', 'clear', 'showFilteringRow', 'hideFilteringRow',
        'dragHeaderToGroup', 'disable', 'enable', 'disable', 'sortAsc', 'sortDesc', 'unsort', 'group', 'ungroup',
        'lockStart', 'lockEnd', 'unlock', 'columns', 'contains', 'startsWith', 'endsWith', 'notContains', 'neq', 'eq', 
        'notEmpty', 'empty', 'lt', 'lte', 'gt', 'gte', 'calendar.todayButtonText', 'calendar.clearButtonText', 
        'calendar.okButtonText', 'calendar.cancelButtonText', 'inrange', 'notinrange', 'inlist', 'notinlist', 'after',
        'afterOrOn', 'before', 'beforeOrOn'
    ]
    const result = {};
    CLAVES.forEach(clave => {
        result[clave] = t(clave)
    });
    return result;
}

export const sanitize = (obj) => {
    return JSON.parse(JSON.stringify(obj, (key, value) => {
      return (value === null ? undefined : value);
    }));
  };