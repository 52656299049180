import React, { FC, useEffect, useMemo, useRef } from 'react'
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getConfig, getLinePaths } from '../../map/redux/MapCRUD';
import { searchTWSData } from '../redux/TwsCRUD';

const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 125px)'
};

const MapPage: FC<any> = ({ filter, setKpiData }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBspnoleivxROkTgIlOam4A9u2mB-u97u8"
        //googleMapsApiKey: "AIzaSyB75vOArqE952iTf4YVg6TrFGZL-_WU_Wg"
    });
    const [centerMap, setCenterMap] = React.useState<any>(null);
    const [zoomMap, setZoomMap] = React.useState<any>(8);
    const [loading, setLoading] = React.useState<any>('');

    const [listaTorresCompleta, setListaTorresCompleta] = React.useState<any>([]);
    const [listaTorres, setListaTorres] = React.useState<any>([]);

    const [pathLines, setPathLines] = React.useState([]);

    const mapRef = useRef<any>(null);

    //carga de config de mapa
    useEffect(() => {
        getConfig().then((response: any) => {
            setCenterMap({ lat: parseFloat(response.data.latitude), lng: parseFloat(response.data.longitude) });
            setZoomMap(response.data.zoom);
        })
    }, [])

    //carga de torres
    useEffect(() => {
        setLoading('Cargando estructuras');
        searchTWSData(filter.startDate, filter.endDate).then((response: any) => {
            setListaTorres(response.data);
            setListaTorresCompleta(response.data);
            setLoading('');
        })

        getLinePaths(filter.lines, [])
        .then((res: any) => {
            setPathLines(res.data);
        });
    }, [filter])

    useMemo(() => {
        if (filter && listaTorresCompleta) {
            let filtradas = listaTorresCompleta;

            const type = filter.type;
            if(type && type.length) {
                filtradas = filtradas.filter(d => d.type === type);
            }
            else {
                filtradas = []; //si no hay type no se muestra nada
            }

            /*
            const linesFilter = filter.lines.filter(line => line.enableFilter);
            if(linesFilter.length) {
                filtradas = filtradas.filter(d => linesFilter.map(line => line.id).indexOf(d.line_id) != -1);
            }
            */
            setListaTorres(filtradas);

            const outZone = filtradas.filter(d => d.type === 'OUT OF ZONE').reduce((a, b) => a + parseInt(b.count), 0);
            const notLocked = filtradas.filter(d => d.type === 'NOT LOCKED').reduce((a, b) => a + parseInt(b.count), 0);
            const lockedNormal = filtradas.filter(d => d.type === 'LOCKED NORMAL').reduce((a, b) => a + parseInt(b.count), 0);
            const lockedHighPriority = filtradas.filter(d => d.type === 'LOCKED HIGH PRIORITY').reduce((a, b) => a + parseInt(b.count), 0);

            const totals = [
                { label: 'outZone', value: outZone, class: "list-group-item-accent-secondary" },
                { label: 'notLocked', value: notLocked, class: "list-group-item-accent-warning" },
                { label: 'lockedNormal', value: lockedNormal, class: "list-group-item-accent-orange" },
                { label: 'lockedHighPriority', value: lockedHighPriority, class: "list-group-item-accent-danger" }
            ]
            setKpiData(totals);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listaTorresCompleta]);

    const onLoad = React.useCallback(
        (mapInstance) => {
            mapRef.current = mapInstance;
        }, []
    );

    return (
        <>
            <LoadingOverlay active={loading !== ''} spinner text={loading + '...'}>
                {
                    isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={centerMap}
                            zoom={zoomMap}
                            onLoad={onLoad}
                            options={{streetViewControl: false}}                
                        >                           
                            {/* LISTA DE TORRES */
                                listaTorres.map((torre: any, index) => {
                                    let url = `/assets/icons/`;
                                    switch(torre.type) {
                                        case 'LOCKED HIGH PRIORITY':
                                            url += 'HIGH_RISK.png';
                                            break;
                                        case 'LOCKED NORMAL':
                                            url += 'MEDIUM_RISK.png';
                                            break;
                                        case 'NOT LOCKED':
                                            url += 'LOW_RISK.png';
                                            break;
                                        default:
                                            url += 'NO_REVISION.png';
                                            break;
                                    }
                                    return (
                                        <Marker
                                            key={'tw_' + index}
                                            position={{ lat: parseFloat(torre.latitude), lng: parseFloat(torre.longitude) }}
                                            icon={{
                                                url: url,
                                                scaledSize: new google.maps.Size(30, 30)
                                            }}
                                        >
                                        </Marker>
                                    )
                                })
                            }

                            {/* PATH LINES */
                                !pathLines ? null :
                                pathLines.map(line => {
                                    return (
                                        <Polyline
                                            key={line.id + "-l"}
                                            path={line.path}
                                            options={{
                                                strokeColor: 'gray',
                                                strokeWeight: 6,
                                                strokeOpacity: 0.6
                                            }}
                                        />
                                    );
                                })
                            }
                
                            <></>
                        </GoogleMap>
                    ) : <></>
                }
            </LoadingOverlay>
            
        </>
    )
}

export { MapPage }