import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Chart } from 'react-chartjs-2';
import { getMaintenanceComplianceKPI } from '../../redux/DashboardCRUD';

const ChartMaintenanceCompliance: React.FC<any> = ({ filter, modal }) => {
  const { t } = useTranslation();
  const [dataKPI, setDataKPI] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (filter) {
      getMaintenanceComplianceKPI(filter)
        .then((res: any) => {
          const valores = res.data.map(d => d.count);
          const noCero = valores.filter(v => v !== '0');

          if (noCero.length) {
            setLabels(res.data.map(d => t(d.estado)));
            setDataKPI(res.data.map(d => d.count));
          }
          else {
            setDataKPI(null);
          }
        })
    }
  }, [filter, t])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5' style={{ minHeight: '40px' }}>
        <label className='fw-bolder mx-auto' style={{ fontSize: modal ? '20px' : '12px', marginBottom: modal ? '1.25rem' : '' }}>{t('scheduledMaintenanceCompliance')}</label>
      </div>
      <div className='card-body mb-0 pt-0' style={{ height: modal ? '450px' : '200px' }}>
        {
          dataKPI ?
            <Chart
              type='doughnut'
              data={{
                labels: labels,
                datasets: [{
                  data: dataKPI,
                  backgroundColor: [
                    'rgb(0,143,57)',
                    'rgb(255,0,0)',
                  ],
                  hoverBackgroundColor: [
                    'rgb(0,143,57)',
                    'rgb(255,0,0)',
                  ],
                  borderWidth: 1
                }]
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom', labels: { font: { size: modal ? 20 : 10 }, padding: 9, boxWidth: 12 }
                  }
                }
              }}
            />
            : <div style={{ marginTop: '25%' }}>
              <h5 className='text-center'>{t('noData')}</h5>
            </div>
        }
      </div>
    </div>
  )
}

export { ChartMaintenanceCompliance };