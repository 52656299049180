/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { mostrarError } from "../../../../../core/EmisorMensajes";
import readXlsxFile from 'read-excel-file'
import { getLines, getTowersLine } from "../../../reports/redux/ReportsCRUD";
import { onlyUnique } from "../../../../helper";
import moment from "moment";

const mapDefinitions = {
    'delete?': 'delete',
    'line_code': 'line_code',
    'tower_name': 'tower_name',
    'rev_date': 'rev_date',
    'rev_number': 'rev_number',
    'issue_number': 'issue_number',
    'issue_code': 'issue_code',
    'issue_description': 'issue_description'
}

type Props = {
    onChangeRevisionData: (data) => void;
    onChangeStructureRevisionList: (data) => void;
    onChangeRevisionList: (data) => void;
    onChangeStructureList: (data) => void;
    onChangeLineRevision: (data) => void;
}

const FileSelector  = (props: Props) => {

    const { onChangeRevisionData, onChangeRevisionList, onChangeStructureList, onChangeLineRevision } = props;

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const [revisionsList, setRevisionsList] = useState([]);
    
    const [lines, setLines] = useState([]);
    const [lineRevision, setLineRevision] = useState<any>(undefined);
    
    const inputFile = useRef<any>();

    const { t } = useTranslation();

    useEffect(() => {
      getLines().then((res: any) => setLines(res.data));
    }, []);

    useEffect(() => {
      onChangeRevisionList(revisionsList);
    }, [revisionsList])
    
    
    useEffect(() => {
        if(lineRevision && lineRevision.id) {
            const body = {
                line_id: lineRevision.id
            }
    
            getTowersLine(body)
            .then((res: any) => {
                // console.log('structures', res.data);
                onChangeStructureList(res.data);
            });
        }
    }, [lineRevision]);

    const subirFichero = () => {

        if(file == null) {
            mostrarError('Debe seleccionar un fichero')
        }

        setRevisionsList([]);

        if(file) {
            setUploading(true);
            readXlsxFile(file, { map: mapDefinitions, sheet: 1, dateFormat: 'yyyy-mm-dd' }).then(({ rows, errors }) => {
                if(errors.length > 0) {
                    console.log('hay errores');
                    return;
                }
                // console.log(rows);
                const rowsToProcess = rows.map((row: any) => ({
                    ...row,
                    number: row.rev_number,
                    date: moment(row.rev_date).format('YYYY-MM-DD HH:mm'),
                    line_name: row.line_code,
                    issue_status: 'NOT_REPAIRED',
                }));
                
                const linesFile = rowsToProcess.map((row: any) => row.line_name);
                const revDetailsFile = rowsToProcess.map((row: any) => 
                    ({ rev_date: moment(row.date).format('YYYY-MM-DD'), rev_number: row.number }));
                const lineCodes = onlyUnique(linesFile);
                const revDetails = onlyUnique(revDetailsFile);

                if (revDetails.length !== 1) {
                    console.error('mas de una revision');
                    return;
                }
                else {
                    const data = {
                        ...revDetails[0],
                        type: 'WEB'
                    }
                    // setRevisionData(data);
                    onChangeRevisionData(data);
                }

                if (lineCodes.length !== 1) {
                    console.error('mas de una linea');
                    return;
                }
                else {
                    const linesRevision = lines.filter(l => l.code == lineCodes[0]);
                    setLineRevision(linesRevision[0]);
                    onChangeLineRevision(linesRevision[0]);
                }
                
                setRevisionsList(rowsToProcess);
                setUploading(false);
            })
        }
    }

    return (  
        <div>
            <div className="row">
                <div className="col-lg-10">
                    <div className='form-group'>
                        <label htmlFor='file'>{t('file')}</label>
                        <input
                            ref={inputFile}
                            className='form-control'
                            type="file"
                            accept=".xls, .xlsx"
                            placeholder={t('selectOneFile')}
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    
                </div>
                <div className="col-lg-2 mt-6">
                    <button 
                        type="button" className="btn btn-sm btn-success m-1" disabled={uploading} onClick={() => subirFichero()}>
                        <i className="fa fa-check" /> {t('uploadFile')}
                    </button>
                </div>
            </div>
        </div>
    );
}
 
export { FileSelector };