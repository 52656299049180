import { FC } from "react";
import { KTSVG } from "../../../_metronic/helpers";

const Icon: FC<any> = ({folder, sub, number}) => (
  <>
    <label>{sub+number}</label>
    <KTSVG
      path={"/media/icons/duotune/"+folder+"/"+sub+number+".svg"}
      className={'svg-icon-1 svg-icon-3x'}
    />
  </>
)

const IconFolder: FC<any> = ({folder, sub, number}) => (
  <>
    <h3>{folder}</h3>
    {[...Array(number)].map((x, i) => {
         let number = "" + (i+1);
         while(number.length !== 3) {
           number = "0" + number;
         }
         
         return (
          <Icon key={i} folder={folder} sub={sub} number={number} />
         )
      })}
  </>
)

const IconsWrapper: FC = () => {
  
  return (
    <>
       <IconFolder folder="abstract" sub="abs" number={52}></IconFolder>
       <IconFolder folder="general" sub="gen" number={56}></IconFolder>
       <IconFolder folder="layouts" sub="lay" number={10}></IconFolder>
       <IconFolder folder="technology" sub="teh" number={10}></IconFolder>
       <IconFolder folder="maps" sub="map" number={10}></IconFolder>
       <IconFolder folder="graphs" sub="gra" number={12}></IconFolder>
       <IconFolder folder="files" sub="fil" number={25}></IconFolder>
       <IconFolder folder="text" sub="txt" number={10}></IconFolder>
       <IconFolder folder="coding" sub="cod" number={10}></IconFolder>
       <IconFolder folder="finance" sub="fin" number={10}></IconFolder>
       <IconFolder folder="communication" sub="com" number={12}></IconFolder>
       <IconFolder folder="ecommerce" sub="ecm" number={11}></IconFolder>
       <IconFolder folder="social" sub="soc" number={10}></IconFolder>
       <IconFolder folder="medicine" sub="med" number={10}></IconFolder>

    </>
  )
}

export {IconsWrapper}
