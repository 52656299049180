import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import StringFilter from "@inovua/reactdatagrid-community/StringFilter";
import { i18nTable, sanitize } from "../../../../../core/Funciones";
import { getReceivers, removeReceiver, saveReceiver } from "../../redux/ReportsCRUD";
import { Button, Modal } from "react-bootstrap-v5";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { MASCARAS } from "../../../../../core/FuncionesCampos";
import { mostrarError, mostrarExito } from "../../../../../core/EmisorMensajes";

const initialValuesReceiver = {
    id: 0, name: '', surname: '', email: '', phone: '', jobDescription: ''
}

const Receiver: FC<any> = ({ handleUpdateData }) => {
    const [receiversList, setReceiversList] = useState<any[]>([]);
    const [receiverSeleccionado, setReceiverSeleccionado] = useState<any>(initialValuesReceiver);
    const [popupEdicionReceiver, setPopupEdicionReceiver] = useState(false);
    const [popupEliminarReceiver, setPopupEliminarReceiver] = useState(false);
    const [refreshData, setRefreshData] = useState(true);

    const { t } = useTranslation();

    useMemo(() => {
        if (refreshData) {
            getReceivers().then((res: any) => setReceiversList(res.data));

            setRefreshData(false);
        }

        return () => {
            setRefreshData(false);         
        };
    }, [refreshData]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().matches(MASCARAS.soloLetrasEspacio, t('soloLetrasEspacio')).required(t('campoRequerido')),
        surname: Yup.string().matches(MASCARAS.soloLetrasEspacio, t('soloLetrasEspacio')).required(t('campoRequerido')),
        email: Yup.string().email(t('emailFormatInvalid')).required(t('campoRequerido')),
        phone: Yup.string().matches(MASCARAS.soloNumeros, t('soloNumeros'))
    });

    const togglePopupReceiver = (data) => {
        setTimeout(() => {
            setPopupEdicionReceiver(!popupEdicionReceiver);
            if (!data) {
                setReceiverSeleccionado(initialValuesReceiver);
            }
            else {
                setReceiverSeleccionado(sanitize(data));
            }   
        });
    }

    const deleteReceiver = () => {
        if (receiverSeleccionado) {
            removeReceiver(receiverSeleccionado.id)
                .then((res: any) => {
                    if (res.status === 200) {
                        mostrarExito(t('recipientDeleted'));
                        setPopupEliminarReceiver(false);
                        setRefreshData(true);
                        handleUpdateData(true);
                    }
                    else {
                        mostrarError(t(res.data));
                    }
                })
                .catch(err => {
                    if (err?.response?.data) {
                        mostrarError(t(err.response.data));
                    }
                    else {
                        mostrarError(err);
                    }
                });
        }
    }

    

    return (
        <div>
            <div className="d-flex justify-content-end">
                <Button className="btn btn-success btn-sm" onClick={() => { setPopupEdicionReceiver(true); }}>
                    <i className="fa fa-plus" /> {t('addRecipient')}
                </Button>
            </div>
            <ReactDataGrid
                idProperty="id"
                style={{ minHeight: 600, height: '75vh' }}
                rowHeight={25}
                columns={[
                    { name: 'surname', header: t('surname'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
                    { name: 'name', header: t('name'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
                    { name: 'email', header: t('email'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
                    { name: 'phone', header: t('phone'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
                    { name: 'jobDescription', header: t('jobDescription'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
                    {
                        name: 'acciones', header: t('actions'), defaultFlex: 1,
                        render: ({ data }) =>
                            <div className="p-0 text-center">
                                <button className="btn btn-sm" onClick={() => togglePopupReceiver(data)}>
                                    <i className="fa fa-edit text-warning"></i>
                                </button>
                                <button className="btn btn-sm" onClick={() => {
                                    setTimeout(() => {
                                        setReceiverSeleccionado(data);
                                        setPopupEliminarReceiver(true);   
                                        handleUpdateData();
                                    });
                                }} >
                                    <i className="fa fa-trash text-danger"></i>
                                </button>
                            </div>
                    },
                ]}
                dataSource={receiversList}
                i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                defaultFilterValue={[
                    { name: 'surname', operator: 'contains', type: 'string', value: undefined },
                    { name: 'name', operator: 'contains', type: 'string', value: undefined },
                    { name: 'email', operator: 'contains', type: 'string', value: undefined },
                    { name: 'phone', operator: 'contains', type: 'string', value: undefined },
                    { name: 'jobDescription', operator: 'contains', type: 'string', value: undefined },
                ]}
            />

            <React.Fragment>
                <Formik
                    initialValues={receiverSeleccionado}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            let receiverData = {
                                id: receiverSeleccionado ? receiverSeleccionado.id : null,
                                ...values
                            }
                
                            saveReceiver(receiverData)
                                .then(res => {
                                    if (res.status === 200) {
                                        mostrarExito(t("changesApplied"));
                                        setPopupEdicionReceiver(false);
                                        setRefreshData(true);
                                        handleUpdateData(true);
                                    }
                                })
                                .catch(err => {
                                    if (err?.response?.data) {
                                        mostrarError(err.response.data);
                                    }
                                    else {
                                        mostrarError(err);
                                    }
                                });
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ isSubmitting, handleChange, handleSubmit, handleReset }) => (
                        <>
                            <Modal
                                show={popupEdicionReceiver}
                                onHide={() => togglePopupReceiver(null)}
                                centered
                                size="lg">
                                <Modal.Header><Modal.Title>{t("recipient")}</Modal.Title></Modal.Header>
                                <Modal.Body>
                                    <div className="form-group row">
                                        <div className='col-lg-6' key='name'>
                                            <div className='form-group'>
                                                <label htmlFor='name'>{t('name')}</label>
                                                <Field className='form-control' type="text" name="name" />
                                                <ErrorMessage name="name" component="div" className="formik-error-message" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6' key='surname'>
                                            <div className='form-group'>
                                                <label htmlFor='surname'>{t('surname')}</label>
                                                <Field className='form-control' type="text" name="surname" />
                                                <ErrorMessage name="surname" component="div" className="formik-error-message" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6' key='email'>
                                            <div className='form-group'>
                                                <label htmlFor='email'>{t('email')}</label>
                                                <Field className='form-control' type="email" name="email" />
                                                <ErrorMessage name="email" component="div" className="formik-error-message" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6' key='phone'>
                                            <div className='form-group'>
                                                <label htmlFor='name'>{t('phone')}</label>
                                                <Field className='form-control' type="text" name="phone" />
                                                <ErrorMessage name="phone" component="div" className="formik-error-message" />
                                            </div>
                                        </div>
                                        <div className='col-lg-12' key='jobDescription'>
                                            <div className='form-group'>
                                                <label htmlFor='jobDescription'>{t('jobDescription')}</label>
                                                <Field className='form-control' as="textarea" name="jobDescription" />
                                                <ErrorMessage name="jobDescription" component="div" className="formik-error-message" />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-secondary btn-sm m-1" 
                                        onClick={() => {
                                            handleReset();
                                            togglePopupReceiver(null);
                                        }}>
                                        <i className="fa fa-times" />{" " + t('cancel')}
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-sm m-1" disabled={isSubmitting} 
                                        onClick={() => handleSubmit()}>
                                        <i className="fa fa-save" />{" " + t('save')}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={popupEliminarReceiver}
                                onHide={() => setPopupEliminarReceiver(false)}
                                centered
                                size="lg">
                                <Modal.Header><Modal.Title>Confirmación</Modal.Title></Modal.Header>
                                <Modal.Body>
                                    <label>{t('confirmDeleteRecipient')}</label>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="m-1 btn btn-sm" onClick={() => setPopupEliminarReceiver(false)}>
                                        <i className="fa fa-times" />{" " + t('cancel')}
                                    </button>
                                    <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => deleteReceiver()}>
                                        <i className="fa fa-save" />{" " + t('confirm')}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
                </Formik>
            </React.Fragment>
        </div>
    );
}

export { Receiver };