import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLines, getReportDataLineZone, getRevisionsPlans, printReport } from "../redux/ReportsCRUD";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { ReportDetail } from "./ReportDetail";
import { exportar } from "../../../../core/Funciones";
import { mostrarInfo } from "../../../../core/EmisorMensajes";

const defaultDropdownButtonStyle = {
    padding: '8px',
    backgroundColor: '#FFF',
    borderRadius: '2px',
    borderWidth: 0,
    //boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.08)',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.36,
    alignItems: 'baseline',
    background: '#FFF',
    boxSizing: 'border-box',
    borderColor: '#eee',
    color: '#515151',
    cursor: 'pointer',
    display: 'inline-flex',
    margin: 0,
    maxWidth: '100%',
    flexWrap: 'nowrap',
    outline: 'currentcolor none medium !important',
    textAlign: 'left',
    textDecoration: 'none',
    transition: 'background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: 'auto',
    ':hover': {
      background: 'white'
    }
};
const initialValues = {
    startDate: '', endDate: '', line: '', revision: []
}
const ReportHistoricLine: FC = () => {
    const [linesList, setLinesList] = useState<any[]>([]);
    const [revisionsList, setRevisionsList] = useState<any[]>([]);
    const [filter, setFilter] = useState<any>(initialValues);
    const [reportData, setReportData] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        getLines().then((res: any) => setLinesList(res.data));

        searchRevisionPlans('');

    }, []);

    useEffect(() => {
        if(filter.line) {
            searchRevisionPlans(filter.line);
        }
    }, [filter.line]);

    const searchRevisionPlans = lineId => {
        getRevisionsPlans(lineId).then((res: any) => {
            res.data.forEach((r, index) => {
                r.label = `N° ${r.number} - ${r.date}`;
                r.value = index;
            });
            setRevisionsList(res.data);
        });
    }

    const search = () => {
        if(filter.line) {
            let revisiones = [];
            if(filter.revision && Array.isArray(filter.revision)) {
                revisiones = filter.revision.filter(r => r.date && r.number);
            }

            const periodValid = ((!filter.startDate && !filter.endDate) || (filter.startDate && filter.endDate && filter.startDate < filter.endDate));
            if(!periodValid) {
                mostrarInfo(t('errorPeriodInvalid'));
                return;
            }

            let filterData = {
                revisions: revisiones,
                lines: filter.line ? [{
                    line_id: Number.parseInt(filter.line)
                }] : [],
                startDate: filter.startDate,
                endDate: filter.endDate
            }

            if(!filter.revision && !filter.lines) {
                mostrarInfo(t('mustSelectLineAndRevision'));
            }
            else{
                setLoading(true);
                getReportDataLineZone(filterData)
                .then((res: any) => {
                    if(res && res.data){
                        if(res.data) {
                            res.data.forEach(data => {
                                data.revisions = revisiones;
                            })
                        }
                        setReportData(res.data);
                        setLoading(false);
                    }
                });
            }
        }
    }

    const printPDF = () => {
        const data = {
            data: reportData
        };

        setLoading(true);
        printReport(data)
        .then(res => {
            if(res && res.data){
                exportar(res.data, 'report_line_details');
                setLoading(false);
            }
        });
    }

    const onChangeMultiselect = (value, event) => {
        let revsSelection = [];
        if (event.action === "select-option" && event.option.value === "*") {
            revsSelection = [{ label: t('all'), value: "*" }, ...revisionsList];
        } 
        else if (event.action === "deselect-option" && event.option.value === "*") {
            revsSelection = [];
        } 
        else if (value.length === revisionsList.length) {
            revsSelection = [{ label: t('all'), value: "*" }, ...revisionsList];
        } 
        else {
            revsSelection = value;
        }

        setFilter({
            ...filter,
            startDate: '', 
            endDate: '',
            revision: revsSelection
        });
    }

    const customStyles = {
        dropdownButton: () => ({
            ...defaultDropdownButtonStyle,
            border: 'solid 1px #eee',
            borderRadius: '5px',
            width: '100%',
        }),
    }

    return (
        <div className="card">
            <div className="card-body">
                <h3>{t('recordPerLine')}</h3>
                <form>
                    <div className="form-group row">
                        <div className='col-lg-3' key='line'>
                            <div className='form-group'>
                                <label htmlFor='line'>{t('line')}</label>
                                <select
                                    className='form-select'
                                    name="line"
                                    value={filter.line}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        line: e.target.value
                                    })}
                                >
                                    <option value="">{t('selectOneOption')}</option>
                                    {
                                        linesList.map(line => (
                                            <option value={line.id} key={line.id}>{line.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3' key='revision'>
                            <div className='form-group'>
                                <label htmlFor='line'>{t('revision')}</label>
                                <ReactMultiSelectCheckboxes
                                    className='form-control multiselect-checkbox'
                                    name="revision"
                                    styles={customStyles}
                                    isClearable={true}
                                    value={filter.revision}
                                    multiple={true}
                                    width={'100%'}
                                    placeholder={t("enterAValue...")}
                                    placeholderButtonLabel={t('selectOneOption')}
                                    getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                                        return value && value.length > 0 ? `${value.length} ítems seleccionados` : placeholderButtonLabel;
                                    }}
                                    onChange={(value, event) => onChangeMultiselect(value, event)}
                                    options={[{ label: t('all'), value: "*" }, ...revisionsList]}
                                >
                                </ReactMultiSelectCheckboxes>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='form-group'>
                                <label htmlFor='start-date'>{t('startDate')}</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    placeholder='dd/mm/aaaa'
                                    name="startDate"
                                    value={filter.startDate}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        revision: [],
                                        startDate: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-lg-2' key='end-date'>
                            <div className='form-group'>
                                <label htmlFor='end-date'>{t('endDate')}</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    placeholder='dd/mm/aaaa'
                                    name="endDate"
                                    value={filter.endDate}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        revision: [],
                                        endDate: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        
                        <div className='col-lg-2'>
                            <button className="btn btn-primary btn-sm mt-7 w-50" type="button" onClick={() => search()} disabled={loading}>
                                <i className="fa fa-search" /> {t('search')}
                            </button>
                            <button className="btn btn-secondary btn-sm mt-7 w-50" type="button" onClick={() => printPDF()} disabled={loading}>
                                {loading ? (
                                    <span className='indicator-progress' style={{display: 'inline'}}>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        {' '}
                                    </span> 
                                ) : <i className="fa fa-print" />}
                            </button>
                        </div>
                    </div>
                </form>
                {
                    reportData ? 
                    <div>
                        {
                            reportData.map((group, index) => (
                                <div className="card mt-5" key={index}>
                                    <div className="card-body">
                                        <ReportDetail reportData={group}></ReportDetail>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div className="card mt-5">
                        <div className="card-header  bg-secondary">
                            <div className="card-title mx-auto">{t('mustSelectLineAndRevision')}</div>
                        </div>    
                    </div>
            }
            </div>
        </div>
    );
}

export { ReportHistoricLine };