import React, {FC, useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap-v5'
import { ChartBacklog } from './charts/ChartBacklog'
import { ChartIssueLocation } from './charts/ChartIssueLocation'
import { ChartIssueResolved } from './charts/ChartIssueResolved'
import { ChartIssueRisk } from './charts/ChartIssueRisk'
import { ChartMaintenanceCompliance } from './charts/ChartMaintenanceCompliance'
import { ChartMaintenanceScheduled } from './charts/ChartMaintenanceScheduled'
import { ChartMTTR } from './charts/ChartMTTR'
import { ChartRevisions } from './charts/ChartRevisions'

const Charts: FC<any> = ({filtro}) => {
  const [filter, setFilter] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [kpiActive, setKpiActive] = useState(null);

  useEffect(() => {
    setFilter(filtro);
  }, [filtro]);

  const activeKPI = (id) => {
    setKpiActive(id);
    setShowModal(true);
  }

  const renderModalKPI = (id) => {
    switch(id) {
      case 1:
        return <ChartMTTR filter={filter} modal={true} />
      case 2:
        return <ChartIssueRisk filter={filter} modal={true} />
      case 3:
        return <ChartIssueLocation filter={filter} modal={true} />
      case 4:
        return <ChartIssueResolved filter={filter} modal={true} />
      case 5:
        return <ChartMaintenanceScheduled filter={filter} modal={true} />
      case 6:
        return <ChartMaintenanceCompliance filter={filter} modal={true} />
      case 7:
        return <ChartRevisions filter={filter} modal={true} />
      case 8:
        return <ChartBacklog filter={filter} modal={true} />
    }
  }

  return (
    <>
      {/* begin::Row */}
      <div className='d-flex flex-row'>
        <div className='col-xl-3 px-0 border border-gray-400 rounded' onClick={() => activeKPI(1)}>
          <ChartMTTR filter={filter} modal={false} />
        </div>
        <div className='col-xl-3 px-0 border border-gray-400 rounded' onClick={() => activeKPI(2)}>
          <ChartIssueRisk filter={filter} modal={false} />
        </div>
        <div className='col-xl-3 px-0 border border-gray-400 rounded' onClick={() => activeKPI(3)}>
          <ChartIssueLocation filter={filter} modal={false} />
        </div>
        <div className='col-xl-3 px-0 border border-gray-400 rounded' onClick={() => activeKPI(4)}>
        <ChartIssueResolved filter={filter} modal={false} />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='d-flex flex-row'>
        <div className='col-xl-3 px-0 border border-gray-400 rounded' onClick={() => activeKPI(5)}>
          <ChartMaintenanceScheduled filter={filter} modal={false} />
        </div>
        <div className='col-xl-3 px-0 border border-gray-400 rounded' onClick={() => activeKPI(6)}>
          <ChartMaintenanceCompliance filter={filter} modal={false} />
        </div>
        <div className='col-xl-3 px-0 border border-gray-400 rounded' onClick={() => activeKPI(7)}>
          <ChartRevisions filter={filter} modal={false} />
        </div>
        <div className='col-xl-3 px-0 border border-gray-400 rounded' onClick={() => activeKPI(8)}>
          <ChartBacklog filter={filter} modal={false} />
        </div>
      </div>
      {/* end::Row */}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">  
        <Modal.Body style={{ minHeight: '500px' }}>    
          {
            renderModalKPI(kpiActive)
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export {Charts}
